import * as userPref from "@common/services/user/userPreferenceService"
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"
import * as pdfService from "@common/services/pdfService"
import { getUserProfile } from "@common/stores/userStore"
import { getCompanySetting } from "@common/stores/companySettingsStore"

angular
    .module('DigiLean')
    .controller("a3wizardController", ['$rootScope', '$scope', '$state', '$stateParams', '$filter', '$translate', 'authService', '$uibModal', 'a3Service', 'suggestionService', 'strategyService','debounce', 'deviationService', 'activityService','modalService','navigationService',
        function ($rootScope, $scope, $state, $stateParams, $filter, $translate, authService, $uibModal, a3Service, suggestionService, strategyService,debounce, deviationService, activityService, modalService, navigationService) {

            $scope.translations = {};
            $scope.a3Project = {};
            $scope.assets = {};
            //$scope.selectedAsset = {};
            $scope.categories = {};
            $scope.selectedCategory = {};
            $scope.selectedVersion = {};
            $scope.selectedOwner = {};
            $scope.selectedStatusOption = {};

            $scope.isStar = false;
            
            $scope.attachmentCount = 0;
            $scope.linkCount = 0;

            $scope.a3Id = parseInt($stateParams.id);
            //$scope.canEdit = false;
            $scope.a3 = {};
            $scope.titlehaserror = false;
            $scope.currentStep = null;
            $scope.currentStepCssState = "animated pulse";
            $scope.updateMessage = "";
            $scope.showComments = false;
            $scope.isPublic = true;

            //strategy
            $scope.hasStrategy = navigationService().hasModule("STRATEGY");
            $scope.goals = [];
            $scope.modifyGoal = false;

            $scope.subscribe("UserAuthenticatedAndReady", function(profile) {
                $scope.hasStrategy = navigationService().hasModule("STRATEGY"); 
                if($scope.goals.length == 0){
                    loadGoals();
                }
            });
            $scope.subscribe("A3StrategyGoalAdded", function(goal) {
                if(!goal || goal.a3Id != $scope.a3Id) return;
                loadGoals();
            });

            $scope.subscribe("A3StrategyGoalDeleted", function(goal) {
                if(!goal || goal.a3Id != $scope.a3Id) return;
                loadGoals();
            });
            function loadGoals(){
                if(!$scope.hasStrategy) return;
                a3Service().getStrategyGoals($scope.a3Id).then(function (relatedGoals) {
                    strategyService().getBreakthroughObjectives().then(function (goals) {
                        if(goals && goals.length > 0){
                            $scope.goals = goals;
                            if(relatedGoals && relatedGoals.length>0){
                                for(var i=0; i<$scope.goals.length; i++){
                                    var matching = $filter('filter')(relatedGoals, { strategyGoalId: $scope.goals[i].id}, true);
                                    if (matching.length > 0) {
                                        $scope.goals[i].selected = true;
                                    }
                                }
                            }
                        }
                    });
                });
            }
            loadGoals();
            $scope.toggleModifyGoal = function () {
                $scope.modifyGoal = !$scope.modifyGoal;
            };
            $scope.toggleGoal = function (goal) {
                if(!$scope.modifyGoal) return;
                goal.selected = !goal.selected;

                if (goal.selected) {
                    a3Service().addStrategyGoalToA3($scope.a3Id, goal.id).then(function (saved) {
                    });
                } else {
                    a3Service().removeStrategyGoalFromA3($scope.a3Id, goal.id).then(function (saved) {
                    });
                }
            };

            $scope.goToStrategyHub = function (goalId) {
                $state.go('goalDetails', { id: goalId }, { reload: false });                
            }
            $scope.commentCountChangedHandler = function(commentCount) {
                updateCommentCount(commentCount);
            };

            $scope.linkCountChangedHandler = function(linkCount) {
                $scope.linkCount = linkCount;
                calculateFileAndLinkCount();
            };

            $scope.participants = [];
            $scope.activityLog = [];

            a3Service().canEdit($scope.a3Id).then(function (canEdit) {
                $scope.canEdit = canEdit;
                $scope.memberoptions = {canEdit: $scope.canEdit};
                // alert(canEdit);
                getA3();
            })

            $scope.currency = customerService.getDefaultCurrency()
            getCompanySetting("currency").then(function (setting) {
                if (setting) {
                    $scope.currency = setting
                }
            })


            $scope.selectedAssetId = null;
            $scope.memberSelectionChanged = function (selection) {
                $scope.selectedAssetId = selection.assetId;
                $scope.participants = selection.participants;
                $scope.selectedAssetType = selection.assetType;
                setOwner();
                if ($scope.a3.assetId != selection.assetId) {
                    $scope.a3.assetId = selection.assetId;
                    $scope.saveA3Metadata();
                }

                if (!selection.assetId && !selection.assetId == 0 && selection.assetType != "CUSTOMIZED") return;
                if (selection.assetId == $scope.a3.assetId  && selection.assetType != "CUSTOMIZED") return;
                updateMembers();
            }

            
            $scope.setPrivateStatus = function (isPublic) {
                $scope.a3.isPrivate = !isPublic;
                a3Service().setPrivateStatus($scope.a3.id, $scope.a3.isPrivate).then(function (result) {
                });
            };

            var updateMembers = function() {
                $scope.isProcessing = true;

                // If new asset, we need to create it then set it to board.

                if ($scope.selectedAssetType == "CUSTOMIZED") {
                    var participants = [];
                    $scope.participants.forEach(p => {
                        var participant = {
                            userId: p.userId,
                            role: "Member"
                        };
                        participants.push(participant);
                    });
                    if ($scope.selectedAssetId == 0) {
                        var customAsset = {
                            name : $scope.name,
                            participants : participants
                        }
                        assetService.createCustomAsset(customAsset).then(function(asset){
                            $scope.selectedAssetId = asset.id;
                            $scope.a3.assetId = asset.id;
                            $scope.saveA3Metadata();
                        });
                    } else {
                        // Update list of participants
                        assetService.updateCustomAssetMembers($scope.selectedAssetId, participants).then(function(asset){
                            $scope.saveA3Metadata();
                        });
                    }
                    
                } else {
                    $scope.saveA3Metadata();
                }
            }
            // Track which tab is selected
            $scope.selectedTabIndex = 0;
            $scope.selectTab = function(index) {
                $scope.selectedTabIndex = index;
                checkTabData(index);
            };

            function checkTabData(index){
                if(index == 2) {//files & links
                    a3Service().getAttachments($scope.a3Id).then(function(attachments) {
                        $scope.a3.attachments = attachments;
                    });
                }

            }
            // Froala editor => options and events
            $scope.contentChanged = function(val) {
                var contents = val;
                if ($scope.currentStep && $scope.anotherUserIsEditingStep === false && isCurrentUserChangingStep === false) {
                    $scope.currentStep.content = contents
                    var contentChanged = {
                        stepId: $scope.currentStep.id,
                        content: contents
                    };
                    var length = contents.length;
                    if (length < 65000) { // We don't want to publish large messages.
                        $scope.publish("SendA3StepContentChanged", contentChanged); // bør debounce
                    }
                    
                    $scope.commitChangesDebounced($scope.currentStep.id, contents); // we will debounce save so we don't save too often.
                }
            };
            $scope.froalaOptions = {
                heightMin: 100,
                toolbarButtons: {
                    'moreText': {
                        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                    },
                    'moreParagraph': {
                        'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'formatUL', 'paragraphFormat', 'alignJustify', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                        'buttonsVisible': 6
                    },
                    'moreRich': {
                        'buttons': ['insertLink', 'insertImage', 'insertTable', 'insertDiagram', 'insertVideo', 'emoticons', 'fontAwesome', 'specialCharacters', 'insertFile', 'insertHR'],
                        'buttonsVisible': 4
                    },
                    'moreMisc': {
                        'buttons': ['undo', 'redo', 'selectAll', 'help'],
                        'align': 'right',
                        'buttonsVisible': 2
                    }
                },
                // toolbarButtons: ['bold', 'italic', 'underline', 
                //     '|', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineStyle', 'paragraphStyle', 
                //     '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertImage', 'insertVideo', 'insertTable', '|', 'emoticons', 'insertHR', 'clearFormatting',
                //     '|', 'help', 
                //     '|', 'undo', 'redo'],
                imageEditButtons: ['editDiagram', 'imageReplace', 'imageAlign', 'imageCaption', 'imageRemove']
            };

            $scope.statusOptions = [
                {
                    statusDB: "New",
                    translated: $translate.instant('COMMON_NEW')
                }, {
                    statusDB: "Plan",
                    translated: $translate.instant('A3_PLAN')
                }, {
                    statusDB: "Do",
                    translated: $translate.instant('COMMON_DO')
                }, {
                    statusDB: "Check",
                    translated: $translate.instant('COMMON_CHECK')
                }, {
                    statusDB: "Act",
                    translated: $translate.instant('COMMON_ACT')
                }, {
                    statusDB: "Closed",
                    translated: $translate.instant('DEVIATION_CLOSED')
                }

            ];

            // When language has changed.
            $rootScope.$on('$translateChangeSuccess', function () {
                
                $scope.statusOptions = [
                    {
                        statusDB: "New",
                        translated: $translate.instant('COMMON_NEW')
                    }, {
                        statusDB: "Plan",
                        translated: $translate.instant('A3_PLAN')
                    }, {
                        statusDB: "Do",
                        translated: $translate.instant('COMMON_DO')
                    }, {
                        statusDB: "Check",
                        translated: $translate.instant('COMMON_CHECK')
                    }, {
                        statusDB: "Act",
                        translated: $translate.instant('COMMON_ACT')
                    }, {
                        statusDB: "Closed",
                        translated: $translate.instant('DEVIATION_CLOSED')
                    }
                ];

                $scope.selectedStatusOption = $scope.statusOptions.find(o => o.statusDB == $scope.a3.status)
            })

            // History
            $scope.getActivityLog = function () {
                var activityRequest = {
                    activity: "A3",
                    activityId: $scope.a3Id,
                    parentActivity: "A3",
                    parentId: $scope.a3Id
                };
                
                var $translate = $filter('translate');
                var translateOptions = {
                    month: $translate('COMMON_TIMEELAPSED_MONTH'),
                    days: $translate('COMMON_TIMEELAPSED_DAYS'),
                    hours: $translate('COMMON_TIMEELAPSED_HOURS'),
                    mins: $translate('COMMON_TIMEELAPSED_MINUTES'),
                    seconds: $translate('COMMON_TIMEELAPSED_SECONDS'),
                    now: $translate('COMMON_TIMEELAPSED_NOW')
                };
                activityService().getLogForActivity(activityRequest).then(function (logItems) {
                    logItems.forEach(function (activity) {
                        activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                        if (activity.payLoad && typeof activity.payLoad === "string") {
                            activity.payLoad = JSON.parse(activity.payLoad); // Payload gives the whole object, in this case the Task.
                        }
                    });
                    $scope.activityLog = logItems;

                    if($scope.a3.a3ProjectId) {
                        var milestoneActivityRequest = {
                            activity: "A3ProjectMilestone", // Can have multiple milestones Ids, so which one to send as activityId?
                            parentActivity: "A3Project",
                            parentId: $scope.a3.a3ProjectId
                        };
                        activityService().getLogForActivity(milestoneActivityRequest).then(function (milestonelogItems) {
                            milestonelogItems.forEach(function (activity) {
                                activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                                if (activity.payLoad && typeof activity.payLoad === "string") {
                                    activity.payLoad = JSON.parse(activity.payLoad); // Payload gives the whole object, in this case the Task.
                                }
                            });
                            $scope.activityLog.push(milestonelogItems);
        
                        });
                    }

                })
            }

            // File upload
            $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
            $scope.fileUploaded = function (file) {
                a3Service().addAttachment($scope.a3.id, file).then(function (attachment) {
                });
            };
            $scope.deleteAttachment = function (attachment) {
                a3Service().deleteAttachment($scope.a3.id, attachment.id).then(function (deleted) {
                    // Send event
                    attachment.cssState = "animated slideOutLeft";
                });
            };

            function calculateFileAndLinkCount(){
                $scope.fileAndLinkCount = 0;
                if($scope.attachmentCount){
                    $scope.fileAndLinkCount = $scope.attachmentCount;
                 }
                 if($scope.linkCount){
                    $scope.fileAndLinkCount += $scope.linkCount;
                 }
                 //$scope.$apply();
            }
            // Change handler for step
            $scope.anotherUserIsEditingStep = false;
            $scope.userEditingStep = {};
            var isCurrentUserChangingStep = false;


            // System events to react to.
            $scope.subscribe('A3Updated', function (a3) {
                if ($scope.a3Id === a3.id) {
                    updateMetaData(a3);
                    setSelectedCategory();
                    setOwner();
                }
            });

            $scope.subscribe('A3ProjectInfoUpdated', function (a3) {
                if ($scope.a3Id === a3.a3Id) {
                    $scope.a3Project = a3;
                    if ($scope.a3Project.estimatedEndDate) {
                        $scope.a3Project.estimatedEndDate = moment($scope.a3Project.estimatedEndDate).toDate();
                    }
                    if ($scope.a3Project.endDate) {
                        $scope.a3Project.endDate = moment($scope.a3Project.endDate).toDate();
                    }
                }
            });

            $scope.subscribe('A3OwnerChanged', function (a3) {
                if ($scope.a3Id === a3.a3Id) {
                    $scope.a3.ownerUserId = a3.ownerUserId;
                    $scope.a3.owner = a3.owner;
                    $scope.a3.ownerDisplayName = null;
                    setOwner();
                }
            });
           
            
            $scope.subscribe('A3AttachmentCountChanged', function (attachment) {
                if ($scope.a3Id === attachment.a3Id) {
                    $scope.attachmentCount = attachment.attachmentCount;
                    calculateFileAndLinkCount();
                }
            });

            $scope.subscribe('A3AttachmentDeleted', function (attachment) {
                if ($scope.a3Id === attachment.a3Id && $scope.a3.attachments) {
                    // remove attachment
                    var deleted = $scope.a3.attachments.find(a => a.id == attachment.id)
                    if (deleted) {
                        attachment.cssState = "animated slideOutLeft";
                        setTimeout(function () {
                            var index = $scope.a3.attachments.indexOf(deleted)
                            $scope.a3.attachments.splice(index, 1)
                            calculateFileAndLinkCount();
                        }, 500)
                    }
                }
            })


            $scope.subscribe('A3AttachmentAdded', function (attachment) {
                if ($scope.a3Id === attachment.a3Id) {
                    var added = $scope.a3.attachments.find(a => a.id == attachment.id)
                    if (added) 
                        return // Already existss
                    attachment.cssState = "animated slideInDown"
                    setTimeout(function () {
                        attachment.cssState = ""
                    }, 500)
                    $scope.a3.attachments.push(attachment)
                }
            })
            

            $scope.subscribe("A3StepUpdated", function (step) {
                var hasStepUpdated = $scope.selectedVersion.steps.find(s => s.id == step.id)
                if (hasStepUpdated && !authService.isCurrentUser(step.lastModifiedByUserId)) {
                    var index = $scope.selectedVersion.steps.indexOf(hasStepUpdated)
                    var stepUpdated = $scope.selectedVersion.steps[index]
                    stepUpdated.content = step.content
                    allowChanges()
                }
            })

            $scope.subscribe("A3StepContentChanged", function (contentChanged) {
                // Check if user has A3 asset before adding
                if (!$scope.currentStep)
                    return
                var hasStepUpdated = $scope.selectedVersion.steps.find(s => s.id == contentChanged.stepId)
                if (hasStepUpdated) {
                    var index = $scope.selectedVersion.steps.indexOf(hasStepUpdated)
                    $scope.selectedVersion.steps[index].content = contentChanged.content
                    // If user has step changed active we need to refresh it.
                    if ($scope.currentStep.id === contentChanged.stepId && !authService.isCurrentUser(contentChanged.changedBy.userId)) {
                        $scope.anotherUserIsEditingStep = true
                        $scope.userEditingStep = contentChanged.changedBy
                        $scope.currentStep.content = contentChanged.content
                        $scope.stepContent = contentChanged.content
                    }
                }
            })

            $scope.subscribe("A3StepApprovedEvent", function (approvedStep) {
                var hasStepUpdated = $scope.selectedVersion.steps.find(s => s.id == approvedStep.a3StepId)
                if (hasStepUpdated) {
                    hasStepUpdated.isApproved = approvedStep.isApproved
                    setApproveButton(approvedStep.isApproved)
                }
            })

            $scope.subscribe("A3VersionCreated", function (version) {
                // Check if user has A3 asset before adding
                if ($scope.a3Id !== version.a3Id) return
                $scope.a3.versions.unshift(version)
            })
            
            $scope.subscribe("A3StatusChanged", function (a3) {
                if (a3.a3Id == $scope.a3.id) {
                    $scope.a3.status = a3.status
                    $scope.selectedStatusOption = $scope.statusOptions.find(o => o.statusDB == a3.status)
                }
            })
            $scope.subscribe("A3GoalChanged", function (a3) {
                if (a3.a3Id == $scope.a3.id) {
                    if (a3.goal) {
                        $scope.a3.goal = a3.goal;
                    } else {
                        $scope.a3.goal = "";
                    }
                }
            });
            $scope.subscribe("A3TitleChanged", function (a3) {
                if (a3.a3Id == $scope.a3.id) {
                    if (a3.title) {
                        $scope.a3.title = a3.title;
                    } else {
                        $scope.a3.title = "";
                    }
                }
            });

            $scope.updateA3Goal = function(goal) {     
                var data = 
                {
                    a3Id : $scope.a3.id,
                    goal: goal
                };
                a3Service().updateA3Goal(data).then(function (result) {                         
                });
                
            }
            $scope.updateA3Title = function(title) {    
                if (!title) return; 
                var a3title = 
                {
                    a3Id : $scope.a3.id,
                    title: title
                };
                a3Service().updateA3Title(a3title).then(function (result) {                         
                });
                
            }

            function updateMetaData(a3) {
                //$scope.a3.title = a3.title;
                //$scope.a3.goal = a3.goal;
                $scope.a3.category = a3.category;
                $scope.a3.assetId = a3.assetId;
                $scope.a3.categoryId = a3.categoryId;
                $scope.a3.status = a3.status;
                $scope.a3.ownerUserId = a3.ownerUserId;
                $scope.a3.owner = a3.owner;
                $scope.a3.isPrivate = a3.isPrivate;
                $scope.a3.ownerDisplayName = a3.ownerDisplayName;
                $scope.a3.hasCustomizedGroup = a3.hasCustomizedGroup;
                $scope.selectedStatusOption = $scope.statusOptions.find(o => o.statusDB == a3.status)
            }


            $scope.projectCompletionDateChanged = function ($event) {
                const date = $event.originalEvent.detail
                $scope.a3Project.estimatedEndDate = date
                $scope.saveProjectdata()
            }

            $scope.projectEndDateChanged = function (event) {
                const orgEvt = event.originalEvent
                const date = orgEvt.detail
                $scope.a3Project.endDate = date
                $scope.saveProjectdata()
            }
            $scope.saveProjectdata = function () {

                a3Service().updateProjectInfo($scope.a3.id, $scope.a3Project).then(function (saved) {
                   
                })
            }

            function setSelectedCategory() {
                if ($scope.a3.categoryId) {
                    $scope.selectedCategory = $scope.categories.find(c => c.id == $scope.a3.categoryId)
                }
                else {
                    $scope.selectedCategory = {}
                }
            }

            function setOwner() {
                if ($scope.a3.ownerUserId) {
                    var owner = $scope.participants.find(p => p.userId == $scope.a3.ownerUserId)
                    if (owner) {
                        $scope.selectedOwner = owner
                    } else { // If not part of participants, we need to add user to list
                        var currentOwner = $scope.allUsers.find(a => a.userId == $scope.a3.ownerUserId)
                        $scope.participants.unshift(currentOwner)
                        $scope.selectedOwner = currentOwner
                    }
                } else {
                    $scope.selectedOwner = {}
                }      
            }

            function updateA3Data(a3) {
                updateMetaData(a3);
                $scope.selectedVersion = $scope.a3.versions[0];
                $scope.selectStep($scope.selectedVersion.steps[0]);
                if (!a3.a3Project) {
                    $scope.a3Project = {
                        estimatedEndDate: null,
                        endDate: null,
                        estimatedTotalCost: null,
                        estimatedSavings: null,
                        actualTotalCost: null,
                        actualSavings: null,
                    };
                } else {
                    $scope.a3Project = $scope.a3.a3Project;
                    if ($scope.a3Project.estimatedEndDate) {
                        $scope.a3Project.estimatedEndDate = moment($scope.a3Project.estimatedEndDate).toDate();
                    }
                    if ($scope.a3Project.endDate) {
                        $scope.a3Project.endDate = moment($scope.a3Project.endDate).toDate();
                    }
                }
            }
            function getA3() {
                a3Service().get($scope.a3Id).then(function (a3) {

                    a3.versions[0].steps = a3.versions[0].steps.filter(s => !s.isProjectInfo)
                    
                    $scope.a3 = a3;
                    $scope.isPublic = !$scope.a3.isPrivate;
                    updateA3Data(a3);
                    $scope.attachmentCount = a3.attachmentCount;
                    $scope.linkCount = a3.linkCount;
                    calculateFileAndLinkCount();
                    a3Service().getCategories().then(function (categories) {
                        $scope.categories = categories;
                        // Set selected category
                        setSelectedCategory();
                    });
                    customerService.getAllUsers().then(function (users) {
                        $scope.allUsers = users;
                        setOwner();
                    });
                    checkFavorite();
                    
                });
            }

            $scope.previousStep = function () {
                var currentIndex = $scope.selectedVersion.steps.indexOf($scope.currentStep);
                if (currentIndex > 0) {
                    $scope.selectStep($scope.selectedVersion.steps[currentIndex - 1]);
                }
            }

            $scope.nextStep = function () {
                var currentIndex = $scope.selectedVersion.steps.indexOf($scope.currentStep);
                if ((currentIndex + 1) < $scope.selectedVersion.steps.length) {
                    $scope.selectStep($scope.selectedVersion.steps[currentIndex + 1]);
                }
            }

            
            $scope.selectStep = function (step) {
                if (!step) return;
                // Set content
                $scope.currentStep = step;
                $scope.stepContent = step.content;
                isCurrentUserChangingStep = true;
                if (!$scope.canEdit) return;

                isCurrentUserChangingStep = false;
                // // Update comment count
                // updateCommentCount();
                setApproveButton(step.isApproved);

                // Set default font-size
                $('.note-editable').css('font-size', '14px');
            }

            function updateCommentCount(commentCount) {
                if (commentCount > 0) {
                    $(".chat-count-counter").text(commentCount);
                    $(".chat-count-counter").show();
                } else {
                    $(".chat-count-counter").hide();
                }
            }

            $scope.delete = function () {
                var a3IdArray = [$scope.a3.id];
                a3Service().deleteA3s(a3IdArray).then(function () {
                    $scope.publish("SendA3sDeleted", a3IdArray);
                    $state.go('A3', {}, { reload: false });
                });
            }


            $scope.ownerChanged = function (owner) {
                if (owner) {
                    $scope.a3.ownerUserId = owner.userId;
                    $scope.a3.owner = owner.firstName + " " + owner.lastName;
                    $scope.a3.ownerDisplayName = owner.displayName;
                    $scope.saveA3Metadata();
                }            
            };

            $scope.categoryChanged = function (category) {
                $scope.a3.categoryId = category.id;
                $scope.saveA3Metadata();
            };

            $scope.statusChanged = function (status) {
                $scope.a3.status = status.statusDB;
                a3Service().updateA3Status($scope.a3Id, $scope.a3.status).then(function () {
                    if($scope.a3.improvementSuggestionId){
                        $translate(['COMMON_CANCEL', 'COMMON_UPDATE_STATUS', 'DASHBOARD_CHANGE_SUGGESTION_STATUS', 'A3_UPDATE_IMPROVEMENT_STATUS']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.COMMON_UPDATE_STATUS,
                                headerText: translations.DASHBOARD_CHANGE_SUGGESTION_STATUS,
                                bodyText: translations.A3_UPDATE_IMPROVEMENT_STATUS
                            };
          
                            modalService.showModal({}, modalOptions).then(function (result) {
                                var improvementStatus = a3Service().getImprovementStatus($scope.a3.status);
                                if(improvementStatus){
                                    var improvementStatus = {
                                        suggestionId:$scope.a3.improvementSuggestionId,
                                        status:improvementStatus
                                    };
                                    suggestionService().updateStatus(improvementStatus).then(function (result) {
                                        //$uibModalInstance.dismiss('cancel');
                                    });
                                }
                                
                            });
                        });
                    }
                });
            };



            // DEVIATION
            $scope.isDeviation = function () {
                if ($scope.a3.deviationId) return true;
                return false;
            };
            $scope.openDeviation = function () {
                deviationService().get($scope.a3.deviationId).then(function (deviation) {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'fullDeviation.html',
                        controller: 'fullDeviationController',
                        windowClass: 'full-screen',
                        resolve: {
                            deviation: function () {
                                return deviation;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                });
            }

            // IMPROVEMENT
            $scope.isImprovementSuggestion = function () {
                if ($scope.a3.improvementSuggestionId) return true;
                return false;
            }
            $scope.openImprovementSuggestion = function () {
                var improvementsuggestionid = $scope.a3.improvementSuggestionId;
                suggestionService().get(improvementsuggestionid).then(function (suggestion) {
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        templateUrl: 'fullImprovementInfoForm.html',
                        controller: 'fullImprovementModalController',
                        windowClass: 'fullSuggestion-modal-window',
                        resolve: {
                            suggestion: function () {
                                return suggestion;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                });
            }

            $scope.saveA3Metadata = function () {
                if (!$scope.a3.id) return;
                if ($scope.a3.title === "") {
                    $scope.titlehaserror = true;
                    return;
                }
                var metaData = {
                    id: $scope.a3.id,
                    assetId: $scope.a3.assetId,
                    isPrivate: $scope.a3.isPrivate,
                    ownerUserId: $scope.a3.ownerUserId,
                    owner: $scope.a3.owner,
                    ownerDisplayName: $scope.a3.ownerDisplayName,
                    categoryId: $scope.a3.categoryId,
                    status: $scope.a3.status,
                    hasCustomizedGroup: $scope.a3.hasCustomizedGroup
                };
                a3Service().saveA3Metadata(metaData).then(function (savedA3) {
                    generatePreview();
                });
            };

            $scope.preview = function () {

                if (!$scope.canEdit) {
                    $state.go('a3PreviewDesigner', { id: $scope.a3.id, version: $scope.selectedVersion.version }, { reload: false });
                    return;
                }
                if (!$scope.currentStep) return;
                $state.go('a3PreviewDesigner', { id: $scope.a3.id, version: $scope.selectedVersion.version }, { reload: false });

            }

            function setApproveButton(status) {
                if (status) {
                    $('.approvestepbutton').hide();
                    $('.approvedstepbutton').show();
                } else {
                    $('.approvedstepbutton').hide();
                    $('.approvestepbutton').show();;
                }
            }

            var commitChanges = function (id, content) {
                var stepContent = {
                    id: id,
                    content: content
                }
                a3Service().saveStep(stepContent).then(function (step) {
                });
            }

            var allowChanges = function () {
                $scope.anotherUserIsEditingStep = false;
            }
            // Debounce function so that save is called to often.
            $scope.commitChangesDebounced = debounce(commitChanges, 2000, false);
            $scope.generatePreviewDebounced = debounce(generatePreview, 10000, false);

            $scope.$on("$destroy", function () {
                generatePreview();
            });

            function generatePreview() {
                let lang = "en"
                const up = getUserProfile()
                
                if (up.preferredLanguage) {
                    lang = up.preferredLanguage
                }

                pdfService.previewA3($scope.selectedVersion.id, lang);

            }

            $scope.addToFavorites = function () {
                $scope.isStar = true;
                userPref.addA3ToFavorites($scope.a3Id, $scope.a3.title)
            }

            $scope.removeFromFavorites = function () {
                $scope.isStar = false
                userPref.removeA3FromFavorites($scope.a3Id)
            }

            function checkFavorite(){
                userPref.getA3Favorites().then(function (favorites) {
                    if (!favorites || !Array.isArray(favorites))
                        return
                    
                    favorites.forEach(a3fav => {
                        if (!a3fav.id)
                            return
                        
                        if(a3fav.id == $scope.a3Id){
                            $scope.isStar = true
                        }
                    })
                })
            }


            // Button events
            $('.stepAttatchment').click(function (event) {
                alert("File attatchment - To be implemented");

                range.insertNode(spn);
            });

            $('.approvestepbutton').click(function (event) {
                var newApprovedStatus = !$scope.currentStep.isApproved;
                var status = {
                    a3Id: $scope.a3Id,
                    isApproved: newApprovedStatus
                };
                a3Service().approveStep($scope.currentStep.id, status).then(function (approved) {
                    $scope.currentStep.isApproved = approved.isApproved;
                    setApproveButton(newApprovedStatus);
                });
            });
            $('.approvedstepbutton').click(function (event) {
                var newApprovedStatus = !$scope.currentStep.isApproved;

                var status = {
                    a3Id: $scope.a3Id,
                    isApproved: newApprovedStatus
                };

                a3Service().approveStep($scope.currentStep.id, status).then(function (approved) {
                    setApproveButton(newApprovedStatus);
                });
            });

            $('.commmentButton').click(function (event) {
                $scope.showComments = !$scope.showComments;
                $scope.$apply();
            });


            $('.stepDescription').click(function (event) {
                var stepDescriptionId = 'A3_STEP_DESCRIPTION_' + $scope.currentStep.sortOrder;

                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'a3StepDescriptionForm.html',
                    controller: 'a3StepDescriptionModalController',
                    resolve: {
                        translationId: function () {
                            return stepDescriptionId;
                        }
                    }
                });
            });
        }]);
