import { JobMonitor, JobPart, JobRun, JobRunLog, JobRunResult, JobRunState, LogMessage, LogMessageType } from "@api"
import { getJobRunLogs } from "@common/services/data/monitoringService"
import dialog from "@common/components/digilean/DigileanDialog"
import { translateLabel } from "@common/services/language/DigiLeanLang"

interface JobRunMatrix {
    nextRun?: string
    main: JobRun[]
    parts: Record<number, JobRun[]>
}

export interface JobMatrix extends Record<number, JobRunMatrix> {}

export function CapJobs (jobs: JobMonitor[], showRuns: number) {
    if (jobs && Array.isArray(jobs)) {
        jobs = jobs.map(j => {
            return capJob(j, showRuns)
        })
    }
    return jobs
}

export function capJob(job: JobMonitor, showRuns: number) {
    if (job.runs && Array.isArray(job.runs) && job.runs.length > showRuns)
        job.runs = job.runs.slice(0, showRuns)
    return job
}

export function UpdateRun (jobs: JobMonitor[], showRuns: number, jobId: number, run: JobRunLog) {
    if (jobs && Array.isArray(jobs)) {
        const job = jobs.find(j => j.id == jobId)
        if (job) {
            if (job.runs && Array.isArray(job.runs)) {
                const runExists = job.runs.find(r => r.runId == run.runId)
                if (runExists) {
                    const index = job.runs.indexOf(runExists)
                    job.runs.splice(index, 1, run)
                } else {
                    job.runs.unshift(run)
                }
            }
            else {
                job.runs = [run]
            }
        }
    }
    CapJobs(jobs, showRuns)
    return jobs
}

export function UpdateRunByLog(jobs: JobMonitor[], showRuns: number, newLog: LogMessage) {
    let { jobId, runId, jobPartId } = newLog
    if (!jobId || !runId) 
        return

    if (!jobs || !Array.isArray(jobs))
        return
    
    const job = jobs.find(j => j.id == jobId)
    if (!job)
        return
    
    updateOneJobRunByLog(job, newLog)
    
    CapJobs(jobs, showRuns)
    return jobs
}

export function updateOneJobRunByLog(job: JobMonitor, log: LogMessage) {
    let { jobId, runId, jobPartId } = log
    if (!jobId || !runId) 
        return

    const clonedJob = structuredClone(job)
    if (clonedJob.runs && Array.isArray(clonedJob.runs)) {
        const runExists = clonedJob.runs.find(r => r.runId == runId)
        if (runExists) {
            if (jobPartId) {
                const jobPartRun = runExists.jobParts.find(p => p.jobPartId == jobPartId)
                if (jobPartRun) {
                    updateRunByLog(jobPartRun, log)
                } else if (log.type == LogMessageType.JobStarted) {
                    let newJobPartRun: JobRun = {
                        jobId: log.jobId!,
                        jobPartId,
                        runId,
                        state: JobRunState.Running,
                        result: JobRunResult.Success,
                        started: new Date().toISOString()
                    }
                    runExists.jobParts.push(newJobPartRun)
                }
            } else {
                updateRunByLog(runExists, log)
            }
        } else if (log.type == LogMessageType.JobStarted || !jobPartId) {
            let newRun: JobRunLog = {
                jobId: log.jobId!,
                runId,
                state: JobRunState.Running,
                result: JobRunResult.Success,
                started: new Date().toISOString(),
                jobParts: []
            }
            clonedJob.runs.unshift(newRun)
        }
    }
    return clonedJob
}

function updateRunByLog(jobPartRun: JobRun, log: LogMessage) {
    if (log.type == LogMessageType.JobFinished) {
        jobPartRun.state = JobRunState.Completed
        jobPartRun.finished = new Date().toISOString()
    }
    else if (log.type == LogMessageType.JobFailed || log.type == LogMessageType.Error) {
        jobPartRun.state = JobRunState.Completed
        jobPartRun.result = JobRunResult.Failed
        jobPartRun.finished = new Date().toISOString()
    }
    
}

export async function refreshRunLog (jobRun: JobRun) {
    if (!jobRun || !jobRun.runId)
        return []
    
    const logs = await getJobRunLogs(jobRun.runId, jobRun.jobPartId)
    return logs
}

export async function openLog (jobId: number, jobRun: JobRun, name: string) {
    const id = `joblog${jobId}${jobRun ? jobRun.jobPartId : ""}`
    let el = document.createElement("monitor-job-log")
    el.id = id
    el.run = jobRun

    const closeLbl = await translateLabel("COMMON_CLOSE")
    dialog.openElement({
        title: `<i>${name}</i>, <translate-label label="ADMIN_MONITOR_RUN_STARTED"></translate-label>: 
                <datetime-viewer date="${jobRun.started}"></datetime-viewer>`,
        hideOkBtn: true,
        cancelBtnText: closeLbl
    }, el)
}

export async function showConfig (jobPart: JobPart) {
    let jsonString = jobPart.config ? jobPart.config : ""
    if (jsonString) {
        const json = JSON.parse(jsonString)
        jsonString = JSON.stringify(json, null, 4)
    }
    const closeLbl = await translateLabel("COMMON_CLOSE")
    const configLbl = await translateLabel("COMMON_CONFIGURATION")
    const template = `<textarea cols="100" rows="20">${jsonString}</textarea>`
    dialog.openHtml({
        title: configLbl,
        hideOkBtn: true,
        cancelBtnText: closeLbl
    }, template)
}