import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import { refreshRunLog } from "@common/components/monitoring/integrations/loggMonitor"
import { JobRun, LogMessage } from "@api"
import { getLastErrors } from "@common/services/data/monitoringService"

@customElement('monitor-job-log')
export class MonitorJobLog extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
        .div-spinner {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
        }
        .error {
            color: var(--digilean-error);
        }
    `

    @property({attribute: false})
    run?: JobRun

    @property({attribute: true})
    connectorid = ""

    @property({attribute: true, type: Number})
    days = 0

    @state()
    loading = false

    @state()
    logs: LogMessage[] = []

    connectedCallback(): void {
        super.connectedCallback()
        this.getLogs()
    }
    getLogs = async () => {
        this.loading = true
        if (this.run) {
            const logsloaded = await refreshRunLog(this.run)
            this.logs = logsloaded
        } else if (this.connectorid) {
            const errors = await getLastErrors(this.connectorid, this.days)
            this.logs = errors
        }
        this.loading = false
    }
    render() {
        return html`
            <h3>
                <translate-label label="ADMIN_DATA_JOB_SHOWLOGS"></translate-label>:
            </h3>
            ${this.loading ? html`
                <div class="div-spinner" v-if="loading">
                    <spinner-icon .spin=${this.loading}></spinner-icon>
                </div>
            ` : html`
                ${this.logs.map(log => html`
                    <logmessage-viewer .log=${log}></logmessage-viewer>
                `)}
            `}
        `
    }
}