import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import { hasDefaultAccess } from "@common/components/dashboard/designer/common/userAccessService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"

angular
    .module('DigiLean')
    .directive("datasourceValuesList", ['$filter', '$sce', 'authService', 'dataService', '$uibModal', '$translate', 'dataSourceService', 'areaService', 'projectService', 'NgTableParams', 'dataListService', 'debounce',
        function ($filter, $sce, authService, dataService, $uibModal, $translate, dataSourceService, areaService, projectService, NgTableParams, dataListService, debounce) {
            return {
                templateUrl: 'datasourceValuesList.html',
                restrict: 'E',
                scope: {
                    'settings': '<',
                    'settingsChangeHandler': '&',
                    'isAdminMode': '<',
                    'layoutFactor': '<',
                    'userAccess': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.title = ""
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.canEdit = true;
                    scope.datalists = [];
                    scope.filters = [];
                    scope.areas = [];
                    scope.assets = [];
                    scope.projects = [];
                    scope.allUsers = [];

                    scope.showDataEntryButton = false;

                    scope.columns = [];
                    scope.hiddencolumns = [];
                    scope.newValuesCounter = 0;
                    scope.totalValues = 0;
                    scope.selectedDataList = [];
                    scope.dataLists = {};
                    scope.isfiltered = false;
                    /* Date format Æ*/
                    scope.showDateWithTime = false;
                    scope.valueDateFormats = [
                        {
                            name: "dateOnly",
                            format: "dateOnly"
                        },
                        {
                            name: "dateWithTime",
                            format: "dateWithTime"
                        },
                    ]
                    scope.valueDateFormat = scope.valueDateFormats[0]; // dateOnly


                    scope.toggleDataEntryButton = function(){
                        scope.showDataEntryButton = !scope.showDataEntryButton;
                        scope.updateSettings();
                    }

                    scope.toggleShowTime = function(){
                        // scope.showDateWithTime = !scope.showDateWithTime;
                        if (scope.showDateWithTime) {
                            scope.valueDateFormat = scope.valueDateFormats[1]
                        } else {
                            scope.valueDateFormat = scope.valueDateFormats[0]
                        }
                        scope.updateSettings();
                    }
                    
                    scope.$watch('userAccess', function (userAccess) {
                        scope.canEdit = hasDefaultAccess(userAccess);
                    });

                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        scope.isAdmin = authService.hasRole("Admin");
                    });

                    scope.subscribe("DataSourceUpdated", function (datasource) {
                        if (!scope.datasource) return;
                        if (datasource.dataSourceId === scope.datasource.id) {
                            scope.setDataSource(datasource.datasourceId);
                        }
                    });

                    scope.subscribe("DataValueAdded", function (value) {
                        if (!scope.datasource) return;
                        if (scope.datasource.id !== value.dataSourceId) return;
                        scope.getLatestValues();
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        if (!scope.datasource) return;
                        if (scope.datasource.id !== value.dataSourceId) return;
                        scope.getLatestValues();
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        if (!scope.datasource) return;
                        if (scope.datasource.id !== value.dataSourceId) return;
                        scope.getLatestValues();
                    });

                    // default timeperiod
                    var timeSettings = {
                        timeframe: "YTD",
                        useDefaultTimeframes: true,
                        timeframes: [],
                        period: null,
                        getTimePeriod: function () {
                            return {
                                useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                                timeframes: timeSettings.timeframes,
                                timeframe: timeSettings.timeframe,
                                period: timeSettings.period
                            }
                        },
                        setTimePeriod: function (timeframe, period) {
                            timeSettings.timeframe = timeframe;
                            timeSettings.period = period;
                            // debounce, to make sure not starting loading several timeframes.
                            scope.options = {
                                timePeriod: timeSettings.getTimePeriod()
                            }
                        }
                    }

                    scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                    // Default options
                    scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        // apply
                        if (settings) {
                            if (settings.filters) {
                                scope.filters = settings.filters;
                            }
                            checkIfFiltered();
                            if (settings.timePeriod) {
                                scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                            }
                            if (settings.hiddencolumns) {
                                scope.hiddencolumns = settings.hiddencolumns;
                            }
                            if (settings.title) {
                                scope.title = settings.title;
                            }
                            if(settings.showDataEntryButton) {
                                scope.showDataEntryButton = settings.showDataEntryButton;
                            }
                            if (settings.valueDateFormat) {
                                scope.valueDateFormat = settings.valueDateFormat;
                                if (scope.valueDateFormat.format == "dateWithTime") {
                                    scope.showDateWithTime = true;
                                }
                            }
                            if (settings.dataSource) {
                                scope.setDataSource(settings.dataSource.id);
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                        }
                    });

                    function checkIfFiltered() {
                        if (scope.filters && scope.filters.length > 0) {
                            scope.isfiltered = true;
                        } else {
                            scope.isfiltered = false;
                        }
                    }

                    scope.changeTheme = function (theme) {
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    function initDataList() {
                        if (!scope.datasource) return;

                        var listOperations = [];
                        angular.forEach(scope.datasource.elements, function (element) {
                            switch (element.type) {
                                case "asset":
                                    listOperations.push(assetService.getAllAssets(true).then(function (data) {
                                        scope.assets = data;
                                    }));
                                    break;
                                case "area":
                                    listOperations.push(areaService().getList(true).then(function (data) {
                                        scope.areas = data;
                                    }));
                                    break;
                                case "project":
                                    listOperations.push(projectService().getList(true).then(function (data) {
                                        scope.projects = data;
                                    }));
                                    break;
                                case "user":
                                    listOperations.push(customerService.getAllUsers().then(function (users) {
                                        scope.allUsers = users;
                                    }));
                                    break;
                                case "list":
                                    var listOptions = { includeDeletedItems: false };
                                    listOperations.push(dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                        if (list) {
                                            scope.dataLists[element.sourceColumn] = list;
                                        }
                                    }));
                                    break;
                            }

                        });

                        Promise.all(listOperations).then(() => {
                            scope.getLatestValues();
                        })
                    }

                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }

                    scope.getLatestValues = function (params) {
                        if (!scope.datasource) return;
                        scope.newValuesCounter = 0;



                        scope.buildColumns(scope.datasource);
                        scope.tableParams = new NgTableParams({
                            sorting: { valueDate: "desc" },
                        },
                            {
                                getData: scope.getTableValues
                            });

                    }

                    scope.getTableValues = function (params) {

                        var sorting = [];
                        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                        for (var propt in params._params.sorting) {
                            sorting.push({
                                property: propt,
                                direction: params._params.sorting[propt]
                            });
                        }
                        // We will build params based on the built-in NgTableParams + our sorting array
                        var dbParams = {
                            page: params._params.page,
                            count: params._params.count,
                            timePeriod: scope.options.timePeriod.period,
                            filters: scope.filters,
                            sorting: sorting
                        }
                        return dataService().getTableValues(scope.datasource.id, dbParams).then(function (data) {
                            angular.forEach(data.values, function (registration) {
                                var asset = $filter('filter')(scope.assets, { id: registration.assetId }, true);
                                if (asset && asset.length > 0) {
                                    registration.assetName = asset[0].name;
                                } else {
                                    if (registration.assetId) {
                                        registration.assetName = registration.assetId;
                                    }
                                }
                                var area = $filter('filter')(scope.areas, { id: registration.areaId }, true);
                                if (area && area.length > 0) {
                                    registration.areaName = area[0].name;
                                } else {
                                    if (registration.areaId) {
                                        registration.areaName = registration.areaId;
                                    }
                                }
                                var project = $filter('filter')(scope.projects, { id: registration.projectId }, true);
                                if (project && project.length > 0) {
                                    registration.projectName = project[0].displayName;
                                } else {
                                    if (registration.projectId) {
                                        registration.projectName = registration.projectId;
                                    }
                                }

                            });
                            scope.totalValues = data.total;
                            params.total(data.total); // recal. page nav controls
                            return data.values;
                        });
                    }
                    scope.buildColumns = function (datasource) {
                        scope.columns = [
                            {
                                title: $translate.instant("COMMON_DATE"),
                                field: 'valueDate',
                                visible: getColumnVisibility('valueDate'),
                                sortable: 'valueDate',
                                getValue: renderValue,
                                dataType: "date"
                            },
                            {
                                title: $translate.instant("COMMON_REGISTRATION"),
                                field: 'registrationDate',
                                visible: getColumnVisibility('registrationDate'),
                                sortable: 'registrationDate',
                                getValue: renderValue,
                                dataType: "timestamp"
                            },
                            {
                                title: $translate.instant("COMMON_VALUE"),
                                field: 'value',
                                sortable: 'value',
                                getValue: renderValue,
                                visible: getColumnVisibility('value'),
                                dataType: "text"
                            }
                        ];
                        var listIndex = -1;
                        for (var i = 0; i < datasource.elements.length; i++) {
                            var element = datasource.elements[i];
                            var dataField = element.sourceColumn;
                            if (dataField === "assetId") dataField = "assetName";
                            if (dataField === "areaId") dataField = "areaName";
                            if (dataField === "projectId") dataField = "projectName";
                            scope.columns.push({
                                title: element.label,
                                field: dataField,
                                getValue: renderValue,
                                visible: getColumnVisibility(dataField),
                                sortable: dataField,
                                dataType: element.type,
                                sourceColumn: element.sourceColumn
                            });
                        }
                    }

                    function getColumnVisibility(datafield) {
                        if (scope.hiddencolumns.length > 0) {
                            var hidden = scope.hiddencolumns.find(q => q.field === datafield);
                            if (hidden) {
                                return false;
                            }
                        }
                        return true;
                    }
                    scope.selectColumn = function (column) {
                        column.visible = !column.visible;
                        scope.hiddencolumns = $filter('filter')(scope.columns, { visible: false });
                        scope.updateSettings();
                    }

                    function renderValue($scope, row) {
                        var item = this;
                        var field = item.field;
                        var value = row[field];
                        if (this.dataType === "date") {
                            if (scope.valueDateFormat && scope.valueDateFormat.format == "dateWithTime") {
                                //return $filter('date')(value, "dd.MM.yyyy HH:mm");
                                return $sce.trustAsHtml(`<datetime-viewer mode="long" date="${value}"></datetime-viewer>`)
                            } else {
                                return $sce.trustAsHtml(`<date-viewer date="${value}"></date-viewer>`)
                                //return $filter('date')(value, "dd.MM.yyyy");
                            }
                        }
                        if (this.dataType === "timestamp") {
                            return $sce.trustAsHtml(`<datetime-viewer mode="long" date="${value}"></datetime-viewer>`)
                            //return $filter('date')(value, "dd.MM.yyyy HH:mm:ss");
                        }
                        if (this.dataType === "bool") {
                            if (value === "true") {
                                return "<i class='far fa-check-square'></i>";
                            } else {
                                return "";
                            }
                        } if (this.dataType === "user") {
                            var user = $filter('filter')(scope.allUsers, { userId: value }, true);
                            if (user.length > 0) {
                                return user[0].fullName;
                            }
                        }
                        if (this.dataType === "list") {
                            var listitem = $filter('filter')(scope.dataLists[this.sourceColumn], { id: parseInt(value) }, true);
                            if (listitem.length > 0) {
                                return listitem[0].name;
                            }
                        }
                        return value;
                    }

                    scope.setDataSource = function (id) {
                        dataSourceService().get(id).then(function (dataSource) {
                            scope.datasource = dataSource;

                            if (!scope.title)
                                scope.title = scope.datasource.title;

                            if(scope.datasource && scope.datasource.objectSource !== 'external'){
                                scope.showDataEntryButton = false;
                            }
                            // Add value property for element
                            angular.forEach(scope.datasource.elements, function (element) {
                                element.value = null;
                            });
                            initDataList();
                        });
                    }


                    scope.updateSettings = function () {
                        if (!scope.datasource) return;
                        var componentSettings = {
                            title: scope.title,
                            timeframe: scope.timeframe,
                            timePeriod: scope.options.timePeriod,
                            dataSource: scope.datasource,
                            filters: scope.filters,
                            showDataEntryButton: scope.showDataEntryButton,
                            hiddencolumns: scope.hiddencolumns,
                            valueDateFormat: scope.valueDateFormat,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    };

                    scope.periodChangedHandler = function (timePeriod) {
                        if (!timePeriod.timeframe) return;
                        scope.options.timePeriod = timePeriod;

                        if (scope.datasource && scope.datasource.id) {
                            scope.getLatestValues();
                        }

                        // if adminmode, then save settings
                        if (scope.isAdminMode) {
                            scope.updateSettings();
                        }
                    };



                    scope.getValueForElement = function (element, item) {
                        var sourceColumn = element.sourceColumn;
                        var value = item[sourceColumn];
                        return value;
                    }

                    scope.selectDataSource = function () {
                        var hasDataSerie = false;
                        if (scope.datasource) {
                            hasDataSerie = true;
                        }
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'dataSourceSingleSelector.html',
                            controller: 'dataSourceSingleSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                hasDataSerie: function () {
                                    return hasDataSerie;
                                },
                                dataSource: function () {
                                    return scope.datasource;
                                },
                                filters: function () {
                                    return scope.filters;
                                },
                                withTarget: function () {
                                    return false;
                                },
                                type: function () {
                                    return null;
                                },
                                externalOnly: function () {
                                    return true;
                                }

                            }
                        });

                        modalInstance.result.then(function (result) {
                            if (scope.datasource && scope.datasource.id != result.dataSource.id) {
                                scope.hiddencolumns = [];
                            }
                            scope.datasource = result.dataSource;
                            scope.filters = result.filters;
                            scope.setDataSource(scope.datasource.id);
                            scope.updateSettings();
                            checkIfFiltered();
                            
                        });
                    };
                    scope.registerData = function () {
                        var settings = {
                            dataSource: scope.datasource,
                            filters: scope.filters /*todo: test if this works*/
                        };

                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'registrationDialog.html',
                            controller: 'registrationDialogController',
                            windowClass: 'fixedwidth-modal-window',
                            resolve: {
                                componentSettings: function () {
                                    return settings;
                                }
                            }
                        });
                        modalInstance.result.then(function (result) {
                        });
                    };

                }

            }
        }]);
