import CountUp from './../../../../../thirdparty/countUp/countUp.min'
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import "./singlevalue.css"
import * as staticFileService from "@common/shared/staticFileService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("singleValue", ['dataService', '$uibModal', 'debounce',
    function (dataService, $uibModal, debounce) {
        return {
            templateUrl: 'singlevalue.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '<',
                'settings': '<',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                
                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)

                scope.title = ""
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }
                // default timeperiod
                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }

                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                scope.filters = [];
                scope.decimals = 0;
                scope.formula = 'sum';
                scope.imageUrl = staticFileService.image("singlevalue.jpg");

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    // apply
                    if (settings) {
                        scope.dataSource = settings.dataSource;
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (settings.title) {
                            scope.title = settings.title;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.decimals) {
                            scope.decimals = settings.decimals;
                        }
                        if (settings.formula) {
                            scope.formula = settings.formula;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                        
                    }

                    if (!scope.dataSource && scope.isAdminMode) {
                        debounceSelectDataSource()
                    }
                });

                // Subscribe to component related event
                scope.subscribe("DataSourceUpdated", function (value) {
                    scope.updateValue(value.dataSource);
                });
                scope.subscribe("DataValueAdded", function (value) {
                    scope.updateValue(value.dataSourceId);
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    scope.updateValue(value.dataSourceId);
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    scope.updateValue(value.dataSourceId);
                });

                scope.changeFormula = function(newFormula){
                    scope.formula = newFormula;
                    scope.updateSettings();
                    scope.updateValue(scope.dataSource.id);
                }

                // Select defintion to use.
                let selectDataSourceModal
                scope.selectDataSource = function () {
                    if (selectDataSourceModal)
                        return
                    
                    let hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    
                    selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceSingleSelector.html',
                        controller: 'dataSourceSingleSelector',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            filters: function () {
                                return scope.filters;
                            },
                            withTarget: function () {
                                return false;
                            },
                            type: function () {
                                return null;
                            },
                            externalOnly: function () {
                                return false;
                            }
                        }
                    });

                    selectDataSourceModal.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        scope.formula = 'sum';
                        scope.filters = result.filters;
                        scope.updateSettings();
                        scope.updateValue(scope.dataSource.id);
                    }, () => {}).finally(() => {
                        selectDataSourceModal = null
                    });
                };
                const debounceSelectDataSource = debounce(() => {
                    scope.selectDataSource()
                }, 200, false)

                // element to update
                var valueElement = $(elem).find(".value")[0];
                scope.updateValue = function (id) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id !== id) return;
                    var options = {
                        timePeriod: scope.options.timePeriod.period,
                        filters: scope.filters

                    };
                    if(scope.formula == "numberofvalues"){
                        dataService().getNumberOfValuesForPeriod(scope.dataSource.id, options).then(function (numberOfValues) {
                            var animoptions = {
                                decimal: '.',
                            };
                            var numAnim = new CountUp(valueElement, 0, numberOfValues, 0, 1, animoptions);
                            numAnim.start();
                        });
                    } else {
                        if (scope.dataSource.valueElement.unit == "%" || scope.formula == "average") {
                            options.formula = "average";
                            scope.formula = "average";
                        }
                        dataService().getSumForPeriod(scope.dataSource.id, options).then(function (sum) {
                            var animoptions = {
                                decimal: '.',
                            };
                            var numAnim = new CountUp(valueElement, 0, sum, scope.decimals, 1, animoptions);
                            numAnim.start();
                        });
                    }
                };
                scope.registerData = function () {
                    var settings = {
                        dataSource: scope.dataSource
                    };

                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'registrationDialog.html',
                        controller: 'registrationDialogController',
                        windowClass: 'fixedwidth-modal-window',
                        resolve: {
                            componentSettings: function () {
                                return settings;
                            }
                        }
                    });
                    modalInstance.result.then(function (result) {
                    });
                };

                scope.updateDecimals = function () {
                    scope.updateValue(scope.dataSource.id);
                    scope.updateSettings();
                };

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timeframe: scope.timeframe,
                        timePeriod: scope.options.timePeriod,
                        dataSource: scope.dataSource,
                        filters: scope.filters,
                        decimals: scope.decimals,
                        formula: scope.formula,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                };

                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    scope.options.timePeriod = timePeriod;

                    if (scope.dataSource && scope.dataSource.id) {
                        scope.updateValue(scope.dataSource.id);
                    }

                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                };

            }
        }
    }]);
