angular
    .module('DigiLean')
    .directive("ownerCell", ['$filter', 'projectService',
        function ($filter, projectService) {
            return {
                templateUrl: 'ownerCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                    'users': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.user = null;

                    scope.$watch("users", function (users) {
                        if (!users) return;
                        mapUser();
                    });

                    var mapUser = function() {
                        if (scope.user === null && scope.model && scope.model.project.ownerUserId && scope.users) {
                            var matchingUser = $filter('filter')(scope.users, { userId: scope.model.project.ownerUserId }, true);
                            if (matchingUser.length > 0) {
                                scope.displayName = matchingUser[0].ownerDisplayName;
                                scope.user = matchingUser[0];
                            }
                        }
                    }

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.project) {
                            if (scope.model.project.ownerUserId) {
                                mapUser();
                            }
                        }
                        if(scope.model){
                            model.getSortValue = function () {
                                if (scope.user) {
                                    return scope.user.fullName;
                                }
                                return "";
                            }
                        }
                    });

                    scope.subscribe("ProjectOwnerUpdated", function (project) {
                        if (project.id == scope.model.project.id) {
                            if(project.ownerUserId)
                            {
                                var matchingUser = $filter('filter')(scope.users, { userId: project.ownerUserId }, true);
                                if (matchingUser.length > 0) {
                                    scope.displayName = matchingUser[0].ownerDisplayName;
                                    scope.user = matchingUser[0];
                                }
                            }
                            else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });
                    
                    scope.clearSelectedUser = function () {
                        var emptyUser = {
                            userId : null,
                            fullName: "",
                            displayName: ""
                        }
                        projectService().updateOwner(scope.model.project.id, emptyUser).then(function (registeredowner) {
                            scope.user = null;
                        });
                    };

                    scope.selectUser = function (user) {
                        scope.user = user;
                        scope.displayname = user.displayName;
                        projectService().updateOwner(scope.model.project.id, user).then(function (registeredowner) {
                        });
                    };
                }
            }
        }]);