angular
    .module('DigiLean')
    .directive("projectPersonCell", ['$filter', 'projectService',
        function ($filter, projectService) {
            return {
                templateUrl: 'projectPersonCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'projectAttributeChangeHandler': '&',
                    'users': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.$watch('model', function (model) {
                        var value = scope.model.projectAttribute.value;
                        if (value) {
                            setValue(value);
                            mapUser();
                        }
                        model.getSortValue = function () {
                            if (scope.user) {
                                return scope.user.fullName;
                            }
                            return "";
                        }
                    });

                    var setValue = function (value) {
                        scope.displayName = value.displayName;
                        scope.user = value;
                    }
                    scope.subscribe("ProjectAttributeUpdated", function (attribute) {
                        if (attribute.projectId == scope.model.project.id && attribute.projectBoardAttributeId == scope.model.boardAttributeId) {
                            if (attribute.value) {
                                setValue(attribute.value);
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });

                    scope.$watch("users", function (users) {
                        if (!users) return;
                        mapUser();
                    });

                    var mapUser = function() {
                        if (scope.user && scope.users) {
                            var matchingUser = $filter('filter')(scope.users, { userId: scope.user.userId }, true);
                            if (matchingUser.length > 0) {
                                scope.displayName = matchingUser[0].ownerDisplayName;
                                scope.user = matchingUser[0];
                            }
                        }
                    }
                    scope.clearSelectedUser = function () {
                        scope.user = null;
                        scope.model.user = null;
                        scope.model.projectAttribute.value = null;
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                    };
                    
                    scope.selectUser = function (user) {
                        scope.user = user;
                        scope.model.user = user;
                        user.assetRole = "Member";
                        scope.model.projectAttribute.value = user;
                        scope.projectAttributeChangeHandler({projectAttribute: scope.model.projectAttribute})
                        if(user){
                            projectService().addUser(scope.model.project.id, user).then(function (user) {
                            });
                        }
                    };
                }
            }
        }]);