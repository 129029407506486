import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"

@customElement('spinner-icon')
export class SpinnerIcon extends LitElement {
    static styles = css`
        :host {
            display: inline-block;
        }
        i {
            color: var(--digilean-primary-text);
        }
    `
    
    @property({attribute: true, type: Boolean})
    spin = false


    render() {
        let icon = "fa fa-redo fa-lg"
        if (this.spin)
            icon = `${icon} fa-spin`
        return html`
            <fa-icon icon="${icon}"></fa-icon>
        `
    }
}
