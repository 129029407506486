import { ConnectorMonitor, JobMonitor } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import "./jobMonitor"

@customElement('connector-monitor-table')
export class ConnectorMonitorTable extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
        .table {
            display: table;
            width: 100%;
        }
        .table-header-group {
            display: table-header-group;
        }
        .table-row {
            display:table-row;
            border: 1px solid var(--digilean-white);
        }
        .table-cell {
            display: table-cell;
            padding: 3px;
            border: 1px solid var(--digilean-white);
        }
        .table-header-group .table-cell {
            font-weight: bold;
        }
    `
    connectorId = ""
    @property({ attribute: true, type: Number})
    numRuns = 7

    @property({attribute: false})
    data: ConnectorMonitor = {
        jobsSetup: [], id: "", name: "", customerId: 0
    }

    render() {
        if (!this.data.jobsSetup)
            return html`<span>Loading</span>`
        return html`
            <div class="table">
                <div class="table-header-group">
                    <div class="table-row">
                        <div class="table-cell">
                            <translate-label label="COMMON_JOBTYPE"></translate-label>
                        </div>
                        <div class="table-cell">
                            <translate-label label="COMMON_NEXT_RUN"></translate-label>
                        </div>
                    </div>
                </div>

                ${this.data.jobsSetup.map(jobSetup => html`
                    <job-monitor-row 
                        .jobSetup=${jobSetup}
                        numRuns=${this.numRuns}>
                    </job-monitor-row>
                `)}
            </div>
        `
    }
}