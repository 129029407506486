import { Job, JobMonitor, JobRun, LogMessage, LogMessageType } from "@api"
import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import * as monitoringService from "@common/services/data/monitoringService"
import { capJob, openLog, showConfig, updateOneJobRunByLog } from "./loggMonitor"
import { systemHub } from "@common/services/systemHub"

@customElement('job-monitor-row')
export class JobMonitorRow extends LitElement {
    static styles = css`
        :host {
            display: table-row-group;
            margin-bottom: 0.5rem;
        }
        .table-row {
            display:table-row;
            &.main-job {
                background: white;
                .table-cell {
                    border: 1px solid var(--digilean-tertiary-background);
                }
            }
            &.hidden {
                display: none;
            }
        }
        .table-cell {
            display: table-cell;
            padding: 3px;
            border: 1px solid var(--digilean-white);
        }
        .part-name {
            cursor: pointer;
        }
        .job-result-cell {
            cursor: pointer;
            padding: 3px;
            display: flex;
            justify-content: flex-start;
            gap: 0.4rem;
            align-items: center;
            height: 3rem;
        }
        .jobpart-cell {
            border-bottom: 1px solid var(--digilean-white);
        }
        fa-icon {
            height: 1.6rem;
            width: 1.6rem;
        }
        span.empty {
            display: inline-block;
            height: 1.6rem;
            width: 1.6rem;
        }
    `

    @property({ attribute: true, type: Number})
    numRuns = 7

    connectorId=""
    private _setup: Job = { id: 0, name: "", jobParts: []}
    
    get jobSetup() {
        return this._setup
    }
    @property({attribute: false})
    set jobSetup(val: Job) {
        this._setup = val
        this.getRuns()
    }
    async getRuns () {
        if (!this.jobSetup)
            return
        try {
            this.connectorId = this.jobSetup.connectorId!
            const monitor = await monitoringService.getJobRuns(this.connectorId, this.jobSetup.id, this.numRuns)
            if (monitor)
                this.jobMonitor = monitor
        }
        catch (e) {}
    }

    openLogDialog(jobRun: JobRun) {
        let name = this.jobSetup.name
        let jobPartId = jobRun.jobPartId ? jobRun.jobPartId : null
        if (jobPartId) {
            if (this.jobSetup.jobParts && this.jobSetup.jobParts.length > 0) {
                const jobPart = this.jobSetup.jobParts?.find(p => p.id == jobPartId)
                if (jobPart)
                    name = jobPart.name!
            }
        }
        openLog(this.jobSetup.id, jobRun, name).then(()=>{}, ()=>{})
    }
    
    connectedCallback(): void {
        super.connectedCallback()
        systemHub.on("NewLogMessage",this._handleLog)
    }
    disconnectedCallback(): void {
        systemHub.off("NewLogMessage",this._handleLog)
        super.disconnectedCallback()
    }

    logEvents = [ LogMessageType.JobStarted, LogMessageType.JobFinished, LogMessageType.JobFailed]
    private _handleLog = (log: LogMessage) => {
        if (log.connectorId == this.connectorId && this.logEvents.includes(log.type!))
        {
            if (this.jobSetup.id && log.jobId && log.runId && this.jobSetup.id === log.jobId) {
                this.updateRunFromLog(log)
            }
        }
    }

    @state()
    jobMonitor: JobMonitor = {id: 0,name: "",runs: []}

    @property({attribute: true, type: Boolean})
    visibleDetails = false

    toggleDetailsVisible() {
        if (this.jobMonitor.runs.length === 0)
            return
        this.visibleDetails = !this.visibleDetails
    }
    
    updateRunFromLog(log: LogMessage) {
        const updatedData = updateOneJobRunByLog(this.jobMonitor, log)
            if (updatedData) {
                capJob(updatedData, this.numRuns)
                this.jobMonitor = updatedData
                this.updateNextRunTime(log)
            }
    }

    async updateNextRunTime(log: LogMessage) {
        if (log.jobId && this.jobMonitor && this.jobMonitor.id == log.jobId &&
            (log.type == LogMessageType.JobStarted || log.type == LogMessageType.JobFinished) && !log.jobPartId) {
            
            let nextRun = await monitoringService.getNextRun(log.jobId).catch(() => {})
            if (nextRun) {
                /// @ts-ignore
                this.jobMonitor.nextTime = nextRun
            }
        }
    }

    render() {
        if (this.jobMonitor.runs.length === 0 && !this.jobMonitor.nextTime) {
            return html`
                <div class="table-row main-job details-hidden">
                    <div class="table-cell part-name">
                        <spinner-icon ?spin=${true}></spinner-icon>
                        <span>${this.jobSetup.name}</span>
                    </div>
                </div>
            `
        }
        
        return html`
            <div class="table-row main-job ${this.visibleDetails ? '' : 'details-hidden'}">
                
                <div class="table-cell part-name" @click=${() => this.toggleDetailsVisible()}>
                    ${this.jobMonitor.runs.length > 0 ? html`
                        ${this.visibleDetails ? 
                            html`<fa-icon icon="fas fa-caret-down"></fa-icon>`:
                            html`<fa-icon icon="fas fa-caret-right"></fa-icon>`}
                        `: html`<span class="empty"></span>`
                    }
                    <span>${this.jobMonitor.name}</span>
                </div>
                <div class="table-cell job-run-cell">
                    <datetime-viewer .date=${this.jobMonitor.nextTime}></datetime-viewer>
                </div>
                ${this.jobMonitor.runs.map(run => html`
                    <div class="table-cell" @click=${() => this.openLogDialog(run)}>
                        <div class="job-result-cell">
                        <jobrun-state .state=${run.state!} .result=${run.result!} .date=${run.started!}></jobrun-state>&nbsp;
                        <datetime-viewer .date=${run.started}></datetime-viewer>&nbsp;
                        <jobrun-duration .jobrun=${run}></jobrun-duration>
                        </div>
                    </div>
                `)}
            </div>
            <!-- Details -->
            <div class="table-row ${this.visibleDetails ? '' : 'hidden'}">
                <div class="table-cell"></div>
                <div class="table-cell">
                    ${this.jobSetup.jobParts?.map(jobPart => html`
                        <div class="part-name job-result-cell jobpart-cell" @click=${() => showConfig(jobPart)}>
                            ${jobPart.name}
                        </div>
                    `)}
                    
                </div>
                ${this.jobMonitor.runs.map(run => html`
                    <div class="table-cell">
                        ${run.jobParts.map(part => html`
                            <div class="job-result-cell jobpart-cell" @click=${() => this.openLogDialog(part)}>
                                <jobrun-state 
                                    .state=${part.state!}
                                    .result=${part.result!}
                                    .date=${part.started!}>
                                </jobrun-state>&nbsp;
                                <jobrun-duration .jobrun=${part}></jobrun-duration>
                            </div>
                        `)}
                    </div>
                `)}
            </div>
        `
    }
}
