angular
    .module('DigiLean')
    .directive('notificationSettings', ['$translate', 'navigationService', 'notificationService',
        function ($translate, navigationService, notificationService) {
            return {
                templateUrl: 'notificationSettings.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    scope.wantsAll = true;
                    var settingUpdated = false;
                    var userSettings = [];
                    refreshModules();
                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        refreshModules();
                    });

                    function refreshModules() {
                        scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT");
                        scope.hasDeviationModule = navigationService().hasModule("DEVIATION");
                        scope.hasA3Module = navigationService().hasModule("A3");
                        scope.hasBoard = navigationService().hasModule("BOARD");
                        scope.hasProjectModule = navigationService().hasModule("PROJECT");
                        scope.hasLearningModule = navigationService().hasModule("LEARNING");
                    }
                    function init() {
                        notificationService().getMyNotificationSettings().then(function (settings) {
                            resetAllEvents();
                            userSettings = settings;
                            if (userSettings == null || userSettings.length == 0) { // user wants all notifications
                                scope.wantsAll = true;
                            } else { // user has unsubscribed all or some events
                                scope.wantsAll = false;
                                if (userSettings[0].key == "All") { // user do not want any notifications
                                    for (var e = 0; e < scope.allEvents.length; e++) {
                                        //scope.allEvents[e].include = false; 
                                        scope.allEvents[e].notMobile = true;
                                        scope.allEvents[e].notEmail = true;
                                    }
                                } else { // user has unsubscribed some events       
                                    for (var e = 0; e < userSettings.length; e++) {
                                        const userSetting = userSettings[e]
                                        var matchingActivity = scope.allEvents.find(evt => evt.key == userSetting.key)
                                        if (matchingActivity) {
                                            var i = scope.allEvents.indexOf(matchingActivity);
                                            scope.allEvents[i].notMobile = userSettings[e].notMobile;
                                            scope.allEvents[i].notEmail = userSettings[e].notEmail;
                                        }
                                    }
                                }
                            }
                        });
                    }
                    init();

                    function resetAllEvents() {
                        userSettings = [];
                        scope.allEvents = [
                            {
                                key: "TaskAssigned",
                                module: "Board",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('BOARD_NOTIFICATION_TASK_ASSIGNED')
                            },
                            {
                                key: "TaskComment",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                module: "Board",
                                translation: $translate.instant('BOARD_NOTIFICATION_TASK_COMMENT')
                            },
                            {
                                key: "UpdatedResponsibleSuggestion",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                module: "Improvement",
                                translation: $translate.instant('IMPROVEMENT_NOTIFICATION_SUGGESTION_ASSIGNED')
                            },
                            {
                                key: "ImprovementChangedStatus",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                module: "Improvement",
                                translation: $translate.instant('IMPROVEMENT_NOTIFICATION_SUGGESTION_STATUS')
                            },
                            {
                                key: "ImprovementComment",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                module: "Improvement",
                                translation: $translate.instant('IMPROVEMENT_NOTIFICATION_SUGGESTION_COMMENT')
                            },
                            {
                                key: "NewDeviation",
                                module: "Deviation",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('DEVIATION_NOTIFICATION_NEW_TO_GROUP')
                            },
                            {
                                key: "UpdatedResponsibleDeviation",
                                module: "Deviation",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('DEVIATION_NOTIFICATION_INCIDENT_ASSIGNED')
                            },
                            {
                                key: "DeviationComment",
                                module: "Deviation",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('DEVIATION_NOTIFICATION_INCIDENT_COMMENT')
                            },
                            {
                                key: "ProjectMessageAdded",
                                module: "Project",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('PROJECT_NOTIFICATION_MESSAGE_ADDED')
                            },
                            {
                                key: "A3Participant", /*NEW id*/
                                module: "A3",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: "A3 du er blitt en deltaker"
                            },
                            {
                                key: "A3Updated",
                                module: "A3",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: "Oppdatert A3 som du er ansvarlig for"
                            },
                            {
                                key: "PageCommentAdded",
                                module: "Learning",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('LEARNING_NOTIFICATION_PAGE_COMMENT')
                            },
                            {
                                key: "CommentMentions",
                                module: "Global",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('COMMON_COMMENT_MENTION')
                            },
                            {
                                key: "WeeklyTaskStatus",
                                module: "UsefulInfo",
                                include: true,
                                notMobile: false,
                                notEmail: false,
                                translation: $translate.instant('COMMON_USEFUL_INFORMATION_TASKSUMMARY')
                            }

                        ];
                    }
                    scope.toggleEmail = function (event) {
                        settingUpdated = true;
                        event.notEmail = !event.notEmail;
                        update();
                        // syncSettings(event);
                    };
                    scope.toggleMobile = function (event) {
                        settingUpdated = true;
                        event.notMobile = !event.notMobile;
                        update();
                        // syncSettings(event);
                    };

            

                    scope.toggleAll = function () {
                        scope.wantsAll = !scope.wantsAll;
                        settingUpdated = true;
                        if (scope.wantsAll) {
                            resetAllEvents();
                         
                        } else {
                         
                            for (var e = 0; e < scope.allEvents.length; e++) {
                                scope.allEvents[e].include = false;
                                scope.allEvents[e].notMobile = true;
                                scope.allEvents[e].notEmail = true;
                            }
                        }
                        update();
                    };

                    function update() {
                        userSettings = [];
                        var wantsNotifications = false;
                        scope.allEvents.forEach(event => {
                            // Set flag if at least one notification is wanted
                            if (!event.notEmail || !event.notMobile) {
                                wantsNotifications = true;
                            }
                            // Include settings where something is turned off / no need to store setting where both is wanted
                            if (event.notEmail || event.notMobile) {
                                userSettings.push({
                                    key: event.key,
                                    notMobile: event.notMobile,
                                    notEmail: event.notEmail
                                });
                            }
                        });
                        if (!wantsNotifications) { // Turn off all notification
                            userSettings = [];
                            var removeAllNotification = {
                                key: "All",
                                notMobile: true,
                                notEmail: true
                            }
                            userSettings.push(removeAllNotification);
                        }
                        notificationService().addNotificationSettings(userSettings)
                    }
                }
            }
        }]);
