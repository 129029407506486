import CountUp from './../../../../../thirdparty/countUp/countUp.min'
import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import "./singlevalueregistrationController.css"
import * as staticFileService from "@common/shared/staticFileService"

var DigiLean = angular.module('DigiLean');
DigiLean.directive("singleValueRegistration", ['$filter', 'dataService', '$uibModal', 'areaService', 'projectService', 'dataListService','debounce', 'settingsService','dataSourceService',
    function ($filter, dataService, $uibModal, areaService, projectService, dataListService, debounce, settingsService, dataSourceService) {
        return {
            templateUrl: 'singlevalueregistration.html',
            restrict: 'E',
            scope: {
                'isAdminMode': '=',
                'settings': '=',
                'userAccess': '<',
                'settingsChangeHandler': '&',
                'globalTimePeriod': '@',
                'layoutFactor': '<'
            },
            link: function (scope, elem, attrs) {
                scope.theme = getDefaultAppTheme()
                scope.themeChangedEvent = function($event) {
                    scope.theme = $event.detail
                    scope.updateSettings()
                }
                
                scope.appStyle = getDefaultAppStyle()
                function calcStyles() {
                    scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                }
                calcStyles()
                scope.$watch('layoutFactor', calcStyles)

                scope.title = "";
                scope.titleChangedEvent = function($event) {
                    scope.title = $event.detail
                    scope.updateSettings()
                }

                scope.isPopupOpen = false;
                scope.isProcessing = false;
                scope.isLoadingConfig = true;
                scope.canEdit = true; // default.
                scope.selectedAsset = null;
                scope.selectedArea = null;
                scope.selectedProject = null;
                scope.selectedDate = moment().startOf('day').toDate(); //removed time portion. Check if this may affect utc date
                scope.value = null;
                scope.description = "";

                scope.selectedDataListItem = null;
                scope.selectedDataList = null;
                scope.datalists = [];

                // default timeperiod
                var timeSettings = {
                    timeframe: "YTD",
                    useDefaultTimeframes: true,
                    timeframes: [],
                    period: null,
                    getTimePeriod: function () {
                        return {
                            useDefaultTimeframes: timeSettings.useDefaultTimeframes,
                            timeframes: timeSettings.timeframes,
                            timeframe: timeSettings.timeframe,
                            period: timeSettings.period
                        }
                    },
                    setTimePeriod: function (timeframe, period) {
                        timeSettings.timeframe = timeframe;
                        timeSettings.period = period;
                        // debounce, to make sure not starting loading several timeframes.
                        scope.options = {
                            timePeriod: timeSettings.getTimePeriod()
                        }
                    }
                }

                scope.setTimePeriodDebounced = debounce(timeSettings.setTimePeriod, 100, false);
                // Default options
                scope.setTimePeriodDebounced(timeSettings.timeframe, timeSettings.period);

                scope.filters = [];
                scope.decimals = 0;
                scope.imageUrl = staticFileService.image("singlevalueregistration.jpg");

                // Dashboard Settings handling
                scope.$watch('settings', function (settings) {
                    // apply
                    if (settings) {
                        scope.dataSource = settings.dataSource;
                        if (settings.timePeriod) {
                            scope.setTimePeriodDebounced(settings.timePeriod.timeframe, settings.timePeriod.period);
                        }
                        if (settings.title) {
                            scope.title = settings.title;
                        }
                        if (settings.filters) {
                            scope.filters = settings.filters;
                        }
                        if (settings.decimals) {
                            scope.decimals = settings.decimals;
                        }
                        if (settings.theme) {
                            scope.theme = settings.theme;
                        }
                    
                    }

                    if (!scope.dataSource && scope.isAdminMode) {
                        setTimeout(function () {
                            scope.selectDataSource();
                        }, 200);
                    }
                });

                scope.$watch('userAccess', function (userAccess) {
                    scope.canEdit = userAccess.isAdmin  || userAccess.assetRole == "Member" || userAccess.assetRole == "Owner";
                });

                // Subscribe to component related event
                scope.subscribe("DataSourceUpdated", function (value) {
                    scope.updateValue(value.dataSource);
                });
                scope.subscribe("DataValueAdded", function (value) {
                    scope.updateValue(value.dataSourceId);
                });
                scope.subscribe("DataValueUpdated", function (value) {
                    scope.updateValue(value.dataSourceId);
                });
                scope.subscribe("DataValueDeleted", function (value) {
                    scope.updateValue(value.dataSourceId);
                });

                scope.changeTheme= function(theme){
                    scope.theme = theme;
                    scope.updateSettings();
                }

                var hasLoadedSource = false;
                var loadDataSource = function() {
                    if (hasLoadedSource) return;
                    dataSourceService().get(scope.dataSource.id).then(function (source) {
                        scope.dataSource = source;
                        hasLoadedSource = true;
                        initRegistration();
                    });
                }

                
                scope.registrationToggle = function(open) {
                    if (!scope.canEdit) {
                        scope.isPopupOpen = false;
                        return;
                    }
                    if (!hasLoadedSource) {
                        loadDataSource();
                    }
                }

                // Select defintion to use.
                let selectDataSourceModal
                scope.selectDataSource = function () {    
                    if (selectDataSourceModal)
                        return

                    var hasDataSerie = false;
                    if (scope.dataSource) {
                        hasDataSerie = true;
                    }
                    selectDataSourceModal = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'dataSourceSingleSelector.html',
                        controller: 'dataSourceSingleSelector',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            hasDataSerie: function () {
                                return hasDataSerie;
                            },
                            dataSource: function () {
                                return scope.dataSource;
                            },
                            filters: function () {
                                return scope.filters;
                            },
                            withTarget: function () {
                                return false;
                            },
                            type: function () {
                                return null;
                            },
                            externalOnly: function () {
                                return true;
                            }

                        }
                    });

                    selectDataSourceModal.result.then(function (result) {
                        if(result.dataSource && (hasDataSerie == false || scope.dataSource.id != result.dataSource.id)){
                            scope.title = result.dataSource.title;
                        }
                        scope.dataSource = result.dataSource;
                        scope.filters = result.filters;
                        initRegistration();
                        scope.updateSettings();
                        scope.updateValue(scope.dataSource.id);
                    }, () => {}).finally(() => {
                        selectDataSourceModal = null
                    });
                };

                scope.open = function() {
                    loadDataSource();
                    scope.isPopupOpen = true;
                }

                scope.cancel = function() {
                    scope.isPopupOpen = false;
                }

                // element to update
                var valueElement = $(elem).find(".value")[0];
                scope.updateValue = function (id) {
                    if (!scope.dataSource) return;
                    if (scope.dataSource.id !== id) return;
                    var formula = null;
                    if (scope.dataSource.valueElement.unit == "%") {
                        formula = "average";
                    }
                    var options = {
                        timePeriod: scope.options.timePeriod.period,
                        filters: scope.filters,
                        formula: formula
                    };
                    dataService().getSumForPeriod(scope.dataSource.id, options).then(function (sum) {
                        var animoptions = {
                            decimal: '.',
                        };
                        var numAnim = new CountUp(valueElement, 0, sum, scope.decimals, 1, animoptions);
                        numAnim.start();
                    });
                };
                scope.registerData = function () {
                    loadDataSource();
                    var settings = {
                        dataSource: scope.dataSource
                    };

                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: true,
                        templateUrl: 'registrationDialog.html',
                        controller: 'registrationDialogController',
                        windowClass: 'fixedwidth-modal-window',
                        resolve: {
                            componentSettings: function () {
                                return settings;
                            }
                        }
                    });
                    modalInstance.result.then(function (result) {
                    });
                };

                scope.updateDecimals = function () {
                    scope.updateValue(scope.dataSource.id);
                    scope.updateSettings();
                };

                scope.updateSettings = function () {
                    var componentSettings = {
                        title: scope.title,
                        timeframe: scope.timeframe,
                        timePeriod: scope.options.timePeriod,
                        dataSource: settingsService().getSettingsFromDataSource(scope.dataSource),
                        filters: scope.filters,
                        decimals: scope.decimals,
                        theme: scope.theme
                    };
                    scope.settingsChangeHandler({ settings: componentSettings });
                };

                scope.periodChangedHandler = function (timePeriod) {
                    if (!timePeriod.timeframe) return;
                    scope.options.timePeriod = timePeriod;

                    if (scope.dataSource && scope.dataSource.id) {
                        scope.updateValue(scope.dataSource.id);
                    }

                    // if adminmode, then save settings
                    if (scope.isAdminMode) {
                        scope.updateSettings();
                    }
                };


                scope.toDate = moment().toDate();

                scope.selectedDateChanged = function ($event) {
                    const date = $event.originalEvent.detail
                    scope.selectedDate = date
                }

                scope.value = null;

                scope.selectAsset = function (asset, item) {
                    scope.selectedAsset = asset;
                    item.value = asset.id;
                }
                scope.selectArea = function (area, item) {
                    scope.selectedArea = area;
                    item.value = area.id;
                }
                scope.selectProject = function (project, item) {
                    scope.selectedProject = project;
                    item.value = project.id;
                }
                scope.selectUser = function (user, item) {
                    item.user = user;
                    item.value = user.userId;
                }
                scope.selectListItem = function (selectedDataListItem, item) {
                    scope.selectedDataListItem = selectedDataListItem;
                    item.value = selectedDataListItem.id;
                }

                function initRegistration() {
                    scope.isProcessing = false;
                    scope.value = null;
                    scope.description = "";
                    scope.selectedDate = moment().startOf('day').toDate();
                    scope.selectedDataListItem = null;
                    angular.forEach(scope.dataSource.elements, function (element) {
                        var hasFilter = $filter('filter')(scope.filters, { sourceColumn: element.sourceColumn }, true);
                        if (hasFilter.length > 0) {
                            if(hasFilter[0].items.length == 1){
                                element.value = hasFilter[0].items[0]; //preselect the first filtered item of this filterelement
                                element.registerValue = false;
                            } else { //multiple selected elements
                                setFilteredSelectors(element, hasFilter[0].items)
                            }
                        }
                        else {
                            element.registerValue = true;
                            switch (element.type) {
                                case "asset":
                                    assetService.getAllAssets(true).then(function (data) {
                                        scope.assets = data;
                                    });
                                    break;
                                case "area":
                                    areaService().getList(true).then(function (data) {
                                        scope.areas = data;
                                    });
                                    break;
                                case "project":
                                    projectService().getList(true).then(function (data) {
                                        scope.projects = data;
                                    });
                                    break;
                                case "user":
                                    customerService.getAllUsers().then(function (users) {
                                        scope.allUsers = users;
                                    });
                                    break;
                                case "list":
                                    var listOptions = {includeDeletedItems:false};
                                    dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                        if (list) {
                                            element.dataList = list;
                                            element.selectedDataListItem = {};
                                        }
                                    });
                                break;
                                default:
                                    element.value = null;

                            }
                        }
                    });

                    scope.isLoadingConfig = false;
                
                }

                function setFilteredSelectors(element, filteritems){
                    element.registerValue = true;
                    switch (element.type) {
                        case "asset":
                            assetService.getAllAssets(true).then(function (data) {
                                scope.assets = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                    if (hasElement.length > 0) {
                                        scope.assets.push(hasElement[0]);
                                    }
                                }
                            });
                            break;
                        case "area":
                            areaService().getList(true).then(function (data) {
                                scope.areas = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                    if (hasElement.length > 0) {
                                        scope.areas.push(hasElement[0]);
                                    }
                                }
                            });
                            break;
                        case "project":
                            projectService().getList(true).then(function (data) {
                                scope.projects = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                    if (hasElement.length > 0) {
                                        scope.projects.push(hasElement[0]);
                                    }
                                }
                            });
                            break;
                        case "user":
                            customerService.getAllUsers().then(function (data) {
                                scope.allUsers = [];
                                for(var i=0; i<filteritems.length; i++){
                                    var hasElement = $filter('filter')(data, { id: filteritems[i] }, true);
                                    if (hasElement.length > 0) {
                                        scope.allUsers.push(hasElement[0]);
                                    }
                                }
                            });
                            break;
                        case "list":
                            var listOptions = {includeDeletedItems:false};
                            dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                if (list) {
                                    element.dataList = [];
                                    for(var i=0; i<filteritems.length; i++){
                                        var hasElement = $filter('filter')(list, { id: filteritems[i] }, true);
                                        if (hasElement.length > 0) {
                                            element.dataList.push(hasElement[0]);
                                        }
                                    }
                                    element.selectedDataListItem = {};
                                }
                            });
                            break;
                        default:
                            element.value = null;
                        }
                }



                scope.save = function () {
                    scope.isProcessing = true;

                    const year = scope.selectedDate.getFullYear();
                    const month = scope.selectedDate.getMonth();
                    const date = scope.selectedDate.getDate();
                    const utcDate = new Date(Date.UTC(year, month, date, 12, 0, 0, 0));



                    var valueString = scope.value.toString();
                    var valuereplacecomma = valueString.replace(/,/g, '.');
                    var registration = {
                        valueDate: utcDate,
                        value: valuereplacecomma,
                        description: scope.description
                    }
                    var saveOK = true;
                    angular.forEach(scope.dataSource.elements, function (element) {
                        if (element.type == 'project') {
                            if (scope.selectedProject && scope.selectedProject.id) {
                                element.value = scope.selectedProject.id;
                                element.dataMissing = false;
                            } else if (element.value) { //filter value is used
                                element.dataMissing = false;
                            } else if (element.isMandatory) {
                                element.dataMissing = true;
                                saveOK = false;
                                element.value = null;
                            }
                        }
                        registration[element.sourceColumn] = element.value;

                        if (element.type !== 'bool' && !element.value && element.isMandatory) {
                            element.dataMissing = true;
                            saveOK = false;
                        } else {
                            element.dataMissing = false;
                        }
                    });
                    if (saveOK) {
                        dataService().add(scope.dataSource.id, registration).then(function (savedRegistration) {
                            scope.isPopupOpen = false;
                            initRegistration();
                        });
                    }
                    scope.isProcessing = false;
                }

            }
        }
    }]);
