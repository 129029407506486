import * as customerService from "@common/services/customers/customerService"
import * as assetService from "@common/services/assetService"

angular
    .module('DigiLean')
    .controller("datavaluesListModalController", ['$scope', '$translate','$filter', '$uibModalInstance', '$sce', 'dataSourceService','dataService', 'areaService', 'authService','projectService', 'dataListService','$uibModal','NgTableParams','options',
        function ($scope, $translate, $filter, $uibModalInstance, $sce, dataSourceService, dataService, areaService, authService, projectService, dataListService,$uibModal, NgTableParams,options) {

            $scope.options = options;
            $scope.dataLists = {};
            $scope.deviations = [];
            $scope.isAdmin = authService.hasRole("Admin");
            $scope.canEdit = true;

            if(options && options.timePeriod && options.dataSource){
                $scope.dataSource = options.dataSource;
                $scope.timePeriod = options.timePeriod;
                $scope.filters = options.filters;
                if(options.canEdit == false || options.canEdit == true)
                {
                    $scope.canEdit = options.canEdit;
                }
                if($scope.dataSource.elements){
                    setUpData();
                } else {
                    dataSourceService().get($scope.dataSource.id).then(function (dataSource) {
                        if(dataSource && dataSource.elements){
                            $scope.dataSource.elements = dataSource.elements;
                        }
                        setUpData();
                    });
                }
                
            } 

            function setUpData(){
                var listOperations = [];
                angular.forEach($scope.dataSource.elements, function (element) {
                    switch (element.type) {
                        case "asset":
                            listOperations.push(assetService.getAllAssets(true).then(function (data) {
                                $scope.assets = data;
                            }));
                            break;
                        case "area":
                            listOperations.push(areaService().getList(true).then(function (data) {
                                $scope.areas = data;
                            }));
                            break;
                        case "project":
                            listOperations.push(projectService().getList(true).then(function (data) {
                                $scope.projects = data;
                            }));
                            break;
                        case "user":
                            listOperations.push(customerService.getAllUsers().then(function (users) {
                                $scope.allUsers = users;
                            }));
                            break;
                        case "list":
                            var listOptions = { includeDeletedItems: false };
                            listOperations.push(dataListService().getItems(element.dataListId, listOptions).then(function (list) {
                                if (list) {
                                    $scope.dataLists[element.sourceColumn] = list; 
                                }
                            }));
                            break;
                    }

                });

                Promise.all(listOperations).then(() => {
                    $scope.getLatestValues();
                })
            }

            $scope.getValueForElement = function (element, item) {
                var sourceColumn = element.sourceColumn;
                var value = item[sourceColumn];
                return value;
            }

            $scope.getLatestValues = function (params) {
                if (!$scope.dataSource) return;
                $scope.newValuesCounter = 0;



                $scope.buildColumns($scope.dataSource);
                $scope.tableParams = new NgTableParams({
                    sorting: { valueDate: "desc" },
                },
                {
                    getData: $scope.getTableValues
                });

            }

            $scope.getTableValues = function (params) {

                var sorting = [];
                // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
                for (var propt in params._params.sorting) {
                    sorting.push({
                        property: propt,
                        direction: params._params.sorting[propt]
                    });
                }
                // We will build params based on the built-in NgTableParams + our sorting array
                var dbParams = {
                    page: params._params.page,
                    count: params._params.count,
                    timePeriod: $scope.timePeriod,
                    filters: $scope.filters,
                    sorting: sorting
                }
                return dataService().getTableValues($scope.dataSource.id, dbParams).then(function (data) {
                    angular.forEach(data.values, function (registration) {
                        var asset = $filter('filter')($scope.assets, { id: registration.assetId }, true);
                        if (asset && asset.length > 0) {
                            registration.assetName = asset[0].name;
                        } else {
                            if (registration.assetId) {
                                registration.assetName = registration.assetId;
                            }
                        }
                        var area = $filter('filter')($scope.areas, { id: registration.areaId }, true);
                        if (area && area.length > 0) {
                            registration.areaName = area[0].name;
                        } else {
                            if (registration.areaId) {
                                registration.areaName = registration.areaId;
                            }
                        }
                        var project = $filter('filter')($scope.projects, { id: registration.projectId }, true);
                        if (project && project.length > 0) {
                            registration.projectName = project[0].displayName;
                        } else {
                            if (registration.projectId) {
                                registration.projectName = registration.projectId;
                            }
                        }

                    });
                    $scope.totalValues = data.total;
                    params.total(data.total); // recal. page nav controls
                    return data.values;
                });
            }
            $scope.buildColumns = function (datasource) {
                $scope.columns = [
                    {
                        title: $translate.instant("COMMON_DATE"),
                        field: 'valueDate',
                        visible: true,
                        sortable: 'valueDate',
                        getValue: renderValue,
                        dataType: "date"
                    },
                    {
                        title: $translate.instant("COMMON_REGISTRATION"),
                        field: 'registrationDate',
                        visible: true,
                        sortable: 'registrationDate',
                        getValue: renderValue,
                        dataType: "timestamp"
                    },
                    {
                        title: $translate.instant("COMMON_VALUE"),
                        field: 'value',
                        sortable: 'value',
                        getValue: renderValue,
                        visible: true,
                        dataType: "text"
                    }
                ];
                var listIndex = -1;
                if(datasource.elements){
                    for (var i = 0; i < datasource.elements.length; i++) {
                        var element = datasource.elements[i];
                        var dataField = element.sourceColumn;
                        if (dataField === "assetId") dataField = "assetName";
                        if (dataField === "areaId") dataField = "areaName";
                        if (dataField === "projectId") dataField = "projectName";
                        $scope.columns.push({
                            title: element.label,
                            field: dataField,
                            getValue: renderValue,
                            visible: true,
                            sortable: dataField,
                            dataType: element.type,
                            sourceColumn: element.sourceColumn
                        });
                    }
                } 
            }

            function renderValue($scope, row) {
                var item = this;
                var field = item.field;
                var value = row[field];
                if (this.dataType === "date") {
                    return $sce.trustAsHtml(`<date-viewer date="${value}"></date-viewer>`)
                    //return $filter('date')(value, "dd.MM.yyyy");
                }
                if (this.dataType === "timestamp") {
                    return $sce.trustAsHtml(`<datetime-viewer mode="long" date="${value}"></datetime-viewer>`)
                    //return $filter('date')(value, "dd.MM.yyyy HH:mm:ss");
                }
                if (this.dataType === "bool") {
                    if (value === "true") {
                        return "<i class='far fa-check-square'></i>";
                    } else {
                        return "";
                    }
                } if (this.dataType === "user") {
                    var user = $filter('filter')($scope.allUsers, { userId: value }, true);
                    if (user.length > 0) {
                        return user[0].fullName;
                    }
                }
                if (this.dataType === "list") {
                    var listitem = $filter('filter')($scope.dataLists[this.sourceColumn], { id: parseInt(value) }, true);
                    if (listitem.length > 0) {
                        return listitem[0].name;
                    }
                }
                return value;
            }

            $scope.registerData = function () {
                var settings = {
                    dataSource: $scope.dataSource,
                    filters: $scope.filters,
                    timePeriod: $scope.timePeriod
                };

                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'registrationDialog.html',
                    controller: 'registrationDialogController',
                    windowClass: 'fixedwidth-modal-window',
                    resolve: {
                        componentSettings: function () {
                            return settings;
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $scope.getLatestValues();
                });
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

        }]);