import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import * as boardService from "@common/services/boardService"
import { hasDefaultAccess } from "@common/components/dashboard/designer/common/userAccessService"


angular
    .module('DigiLean')
    .directive("actionListApp", ['$filter', 'actionlistService', '$uibModal', 'boardTaskService', 'taskDrawingService', 
        function ($filter, actionlistService, $uibModal, boardTaskService, taskDrawingService) {
            return {
                templateUrl: 'actionListApp.html',
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'settings': '<',
                    'settingsChangeHandler': '&',
                    'assetId': '<',
                    'layoutFactor': '<',
                    'userAccess': '<'
                },
                link: function (scope, element, attrs) {

                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.canEdit = true;

                    scope.$watch('userAccess', function (userAccess) {
                        scope.canEdit = hasDefaultAccess(userAccess);
                    });

                    scope.title = translateLabelInstant("BOARD_ACTION_LIST") // default value
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }
                    scope.actionList = null;
                    scope.currentView = null;
                    scope.isLoading = true;
                    scope.board = null;
                    scope.tasks = null;
                    scope.items = [];
                    scope.title = null;
                    

                    scope.subscribe("CreateActionListItem", function (status) {
                        getActionListTasks();
                    });
                    scope.subscribe("TaskDeleted", function (status) {
                        getActionListTasks();
                    });
                    scope.subscribe("TaskUpdated", function (status) {
                        getActionListTasks();
                    });

                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        if (settings) {
                            scope.title = settings.title;
                            if (settings.targetDate) {
                                scope.targetDate = moment(settings.targetDate).toDate();
                            } else {
                                scope.targetDate = moment().toDate();
                            }
                            if (settings.theme) {
                                scope.theme = settings.theme;
                            }
                            scope.boardId = settings.boardId;
                            if (scope.boardId)
                                boardService.get(scope.boardId).then(function (result) {
                                    scope.board = result.board;
                                    getActionListTasks();
                                    getUsers();
                                });
                        }
                        else {
                            scope.settings = {};
                            console.log("creating board");
                            boardService.create({ board: { boardType: "ActionList", assetId: scope.assetId } }).then(function (result) {
                                scope.board = result.board;
                                scope.boardId = result.board.id;
                                scope.updateSettings();
                                //scope.settings.boardId = scope.board.id;
                                //scope.settingsChangeHandler({ settings: scope.settings });
                                getActionListTasks();
                                getUsers();
                            });

                        }
                    });

                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }


                    var getActionListTasks = function () {
                        boardService.getActionListTasks(scope.board.id).then(function (tasks) {
                        
                            scope.tasks = tasks.sort((a,b) => {
                                a = new Date(a.boardDate);
                                b = new Date(b.boardDate);
                                return a > b ? 1 : a < b ? -1 : 0;
                                });
                            scope.isLoading = false;
                        });
                    }

                    scope.updateSettings = function () {
                        var componentSettings = {
                            title: scope.title,
                            targetDate: scope.targetDate,
                            boardId: scope.boardId,
                            theme: scope.theme
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });

                    }

                    scope.editTask = function (task, event) {
                        $uibModal.open({ backdrop: 'static',
                            animation: scope.animationsEnabled,
                            templateUrl: 'actionListEditTask.html',
                            controller: 'actionListEditTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                date: function(){
                                    return new Date(task.boardDate);
                                }
                            }
                        });
                        event.stopPropagation();
                    };

                    scope.newTask = function () {
                        $uibModal.open({ backdrop: 'static',
                            animation: scope.animationsEnabled,
                            templateUrl: 'actionListNewTask.html',
                            controller: 'actionListNewTaskController',
                            resolve: {
                                boardid: function () {
                                    return scope.board.id;
                                },
                                date: function(){
                                    return new Date();
                                }
                            }
                        });
                    };

                    scope.changeStatus = function (item) {
                        if (scope.readonly) return;
                        item.status = taskDrawingService().getNextStatus(item.status).status;
                        var status = {
                            taskId: item.id,
                            status: item.status
                        };
                        boardTaskService().updateStatus(item.id, status).then(function (taskIds) {
                            
                        });
                        
                    }
                    scope.subscribe('TaskStatusUpdated', function (status) {
                        
                            var tasks = $filter('filter')(scope.tasks, { id: status.taskId }, true);
                            if (tasks.length > 0) {
                                tasks[0].status = status.status;
                                tasks[0].dateStyleClass = actionlistService().getTaskDateStyle(tasks[0]);
                            }
                            
                        
                    });


                    // load users
                    var users = [];
                    var getUsers = function () {
                        boardService.getUserListForBoard(scope.board.id).then(function (data) {
                            users = data;
                            // initalizeBoard();
                        });
                    }

                    // Get Calendar tasks for period => transform to events 
                    scope.tasks = [];


                }
            }
        }]);
