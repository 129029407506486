﻿import { getUserProfile } from "@common/stores/userStore"
import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import * as employeeService from "@common/services/employeeService"
import * as profileImageService from "@common/services/user/profileImageService"


var DigiLean = angular.module("DigiLean");
DigiLean.controller('boardTaskController', ['$scope', '$filter', '$uibModalInstance', '$uibModal', '$translate', 'boardTaskService', 'activityService', 'task', 'activeTab', 'taskDrawingService', 'options',
    function ($scope, $filter, $uibModalInstance, $uibModal, $translate, boardTaskService, activityService, task, activeTab, taskDrawingService, options) {
        $scope.task = task;
        if (task.parentId) {
            // load parentInfo
            boardTaskService().getInfo(task.parentId).then(function (taskInfo) {
                $scope.parent = taskInfo;
            });
        }
        $scope.selectedEmployee = {};
        $scope.employees = null;
        var allUsers = null;
        $scope.title = $translate.instant('COMMON_TASK');
        $scope.parentTitle = $translate.instant('COMMON_PARENT_TASK');
        var translatedPersonalBoard = $translate.instant('BOARD_PERSONAL_BOARD');
        $scope.showArchive = false;
        $scope.startdateAvailable = false;

        var origionalBoardDate = task.boardDate;

        $scope.boardoptions = {
            mandatory: true
        }

        
        var personalBoard = {
            name: translatedPersonalBoard,
            id: null,
            boardType: "PERSONAL"
        }

        $scope.emptyUser = {
            id: '',
            userName: null,
            profileImageUrl: null,
            fullName: null,
            displayName: null
        };

        // colors  
        $scope.background = "var(--digilean-secondary-background)"; //"#ffffff"; 
        $scope.customColorBackground = "#A9A9A9" //"var(--digilean-greydark-default)"; //"#A9A9A9"
        $scope.postIts = taskDrawingService().getPostIts();

        $scope.canChangeBoard = false;
        $scope.boards = [];

        $scope.showAddComment = false;
        $scope.isLoadingComments = true;
        const currentUser = getUserProfile()
        $scope.currentUser = currentUser;
        $scope.showAddComment = true;
        $scope.isLoadingComments = false;

        if (options) {
            $scope.options = options;
            if ($scope.options == null) return;
            if ($scope.options.archiveAvailable) {
                $scope.archiveAvailable = true;
            }
            if ($scope.options.startdateAvailable) {
                $scope.startdateAvailable = true;
            }
            if ($scope.options.users) {
                $scope.employees = options.users;
            }
            if ($scope.options.postIts) {
                $scope.postIts = $scope.options.postIts;
                setPostitTooltip();
            }
            if (typeof $scope.options.canChangeBoard !== 'undefined') {
                if ($scope.options.canChangeBoard) {
                    setCanChangeBoard();
                }
            } else {
                if (task.isActionItem) {
                    setCanChangeBoard();
                }
            }

            if (!$scope.postIts) {
                $scope.postIts = taskDrawingService().getPostIts();
            }
            if (task.color) {
                $scope.background = task.color;
                $scope.taskBorderStyle = {
                    "border-bottom": "6px solid" + $scope.background
                };
                var predefinedPostIt = $filter('filter')($scope.postIts, { background: $scope.background });
                if (predefinedPostIt.length > 0) {
                    setTimeout(function () {
                        $scope.selectColor(predefinedPostIt[0]);
                    }, 10);
                } else //Custom color 
                {
                    $scope.selectedPostIt = { title: "custom", background: $scope.customColorBackground };
                    //$scope.customColorBackground = task.color;
                }
            }
        } else {
            if (task.isActionItem) {
                setCanChangeBoard();
            }
        }


        if (task.color) {
            $scope.background = task.color;
            var predefinedPostItBackground = $filter('filter')($scope.postIts, { background: $scope.background });
            var predefinedPostItForeground = $filter('filter')($scope.postIts, { fillColor: $scope.background });
            if (predefinedPostItBackground.length > 0) {
                $scope.selectedPostIt = predefinedPostItBackground[0];
            }
            else if (predefinedPostItForeground.length > 0) {
                $scope.selectedPostIt = predefinedPostItForeground[0];
            }
            else //Custom color 
            {
                $scope.selectedPostIt = { title: "custom", background: $scope.customColorBackground };
                $scope.customColorBackground = task.color;
            }
        }

        
        if (task.boardDate) {
            // Make sure boardDate is date object 
            task.boardDate = moment(task.boardDate).toDate();
        }

        $scope.subscribe('TaskCommentAdded', function (c) {
            if (c.boardTaskId == $scope.task.id) {
                task.commentCount = c.taskCommentCount;
            }
        });

        $scope.subscribe('TaskCommentDeleted', function (c) {
            if (c.boardTaskId == $scope.task.id) {
                $scope.task.commentCount = c.taskCommentCount;
            }
        });

        $scope.subscribe("SubTaskCountChanged", function (count) {
            if (count.taskId == $scope.task.id || count.taskId == $scope.task.siblingId) {
                $scope.task.subTaskCount = count.subTaskCount;
                $scope.task.subTaskCompletedCount = count.subTaskCompletedCount;
            }
        });

        $scope.subscribe('TaskChangedBoard', function (change) {
            if(change.taskId == $scope.task.id ){
                $scope.task.boardId = change.toBoardId;
                setBoard(true);
            } 
        });


        $scope.canEdit = false;
        // Chceck if user is allowed to edit task
        boardTaskService().canEdit(task.id).then(function (canEdit) {
            $scope.canEdit = canEdit;
        });

        function setPostitTooltip() {
            $scope.postIts.forEach(color => {
                if (color.tags && color.tags.length > 0) {
                    color.tooltip = getTagsDescription(color);
                } else {
                    color.tooltip = "";
                }
            });
        }
        function getTagsDescription(postIt) {
            var tooltip = "";
            if (postIt.tags) {
                for (var i = 0; i < postIt.tags.length; i++) {
                    if (i > 0) {
                        tooltip = tooltip.concat(', ');
                    }
                    tooltip = tooltip.concat(postIt.tags[i]);
                }
            }
            return tooltip;
        }

        employeeService.getList().then(function (data) {
            allUsers = data;
            
            if ($scope.task && $scope.task.responsibleUserId) {
                // Use false on filter, because sometimes responsibleUserId varies between uppercase and lowercase.
                $scope.task.user = $filter('filter')(allUsers, { userId: $scope.task.responsibleUserId }, false)[0];
                profileImageService.getProfileImageUrl($scope.task.user.id, "ExtraSmall").then(function (url) 
                {
                    $scope.task.user.profileImageUrl = url;
                });
            }
        });


        function setCanChangeBoard() {
            $scope.canChangeBoard = true;
            if ($scope.boards.length == 0) {
                getMemberBoards();
            }
        }

        function setBoard(getUsersForBoard) {
            if (task.boardId) {
                $scope.selectedBoard = $filter('filter')($scope.boards, { id: task.boardId })[0];
                $scope.task.board = $scope.selectedBoard; //this is used to prevent subtask from smartactionlist to be placed in the same board as parent (see subTaskList)
                if (getUsersForBoard) {
                    boardService.getUserListForBoard(task.boardId).then(function (data) {
                        angular.forEach(data, function (employee, key) {
                            employee.fullName = employee.firstName + " " + employee.lastName;
                        });
                        $scope.employees = data;
                    });
                }
            } else {
                $scope.selectedBoard = personalBoard;
                if (allUsers) {
                    $scope.employees = allUsers;
                } else {
                    employeeService.getList().then(function (data) {
                        $scope.employees = data;
                    });
                }

            }
        }

        function getMemberBoards() {
            boardService.getMemberBoards().then(function (data) {
                $scope.boards = data;
                $scope.boards.unshift(personalBoard);
                var getUsersForBoard = !$scope.employees;    // If we have not yet loaded users, we need to load them when board is selected.
                setBoard(getUsersForBoard);
            });
        }

       
        function checkDate() {
            $scope.enddateBeforeStartdate = false;
            if (moment($scope.task.boardDate).isBefore($scope.task.startTime)) {
                // Validation kicks in but need to verify if startvalidation should be used 
                if ($scope.startdateAvailable) {
                    $scope.enddateBeforeStartdate = true;
                } else { // if we are not using start date, we need to keep it in sync
                    $scope.task.startTime = $scope.task.boardDate
                }
            }
        }
        $scope.descriptionChanged = function (content) {
            $scope.task.text = content;
        }
        $scope.updateResponsible = function (newResponsible) {
            newResponsible.userDisplayName = newResponsible.displayName;
            $scope.task.responsibleUserId = newResponsible.id;
            $scope.task.responsibleUser = newResponsible.fullName;
            $scope.task.responsibleDisplayName = newResponsible.displayName;
            newResponsible.userName = newResponsible.fullName;
            $scope.task.user = newResponsible;

            changedResponsible = true;
        };

        $scope.selectColor = function (postIt) {
            $scope.selectedPostIt = postIt;
            $scope.background = postIt.background;
            if (postIt.tags) {
                setTimeout(function () {
                    $("#taskTags").tagit({
                        // This will make Tag-it submit a single form value, as a comma-delimited field.
                        singleField: true,
                        singleFieldNode: $('#tagsSelected')
                    });
                    var tagArray = postIt.tags;
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i]);
                    }
                }, 10);
            }
        }
        $scope.selectCustomColor = function (color) {
            $scope.selectedPostIt = { title: "custom", background: color };
            $scope.background = color;
        }

        $scope.task = task;
        if ($scope.task.startTime) {
            // Make sure boardDate is date object 
            $scope.task.startTime = moment($scope.task.startTime).toDate();
        }
        $scope.comments = [];
        $scope.activityLog = [];
        $scope.isProcessing = false;

        // Change flags
        $scope.changedDescription = false;
        $scope.changedTags = false;
        $scope.changedDueDate = false;
        $scope.changedResponsible = false;
        $scope.hasAddedComments = false;
        $scope.changedStatus = false;
        var changedBoard = false;
        var changedResponsible = false;
        $scope.changedStartDate = false;

        // Support for lazy loading to avoid eager call of tabs not yet selected:
        $scope.showSubTasks = false;

        var tabs = ["Info", "SubTasks", "Comments", "Attachments", "History"];
        $scope.indexTab = tabs.indexOf(activeTab);
        if(activeTab == 'SubTasks') {
            $scope.showSubTasks = true;
        }
        $scope.selectTab = function (tab) {
            $scope.indexTab = tabs.indexOf(tab);
            if (tab == 'Comments' && !$scope.employees) {
                if (allUsers) {
                    $scope.employees = allUsers;
                } else {
                    employeeService.getList().then(function (data) {
                        $scope.employees = data;
                    });
                }
            }
            if (tab == 'SubTasks') {
                $scope.showSubTasks = true;
            }
        }

        // Init tags and focus
        setTimeout(function () {

            $("#taskTags").tagit({
                // This will make Tag-it submit a single form value, as a comma-delimited field.
                singleField: true,
                singleFieldNode: $('#tagsSelected')
            });
            if ($scope.task.tags) {
                var tagArray;
                if (typeof stringValue === "string") {
                    tagArray = $scope.task.tags.split(",");
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i]);
                    }
                } else {
                    tagArray = $scope.task.tags;
                    for (var i = 0; i < tagArray.length; i++) {
                        $("#taskTags").tagit('createTag', tagArray[i].tag);
                    }
                }
            }
        }, 50);


        // File upload
        $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
        $scope.fileUploaded = function (file) {
            boardTaskService().addAttachment($scope.task.id, file).then(function (attachment) {
                attachment.cssState = "animated slideInDown";
                setTimeout(function () {
                    attachment.cssState = "";
                }, 500);
                $scope.attachments.push(attachment);
                // Send event
                $scope.publish("SendTaskAttachmentAdded", attachment);
            });
        };
        $scope.deleteAttachment = function (attachment) {
            boardTaskService().deleteAttachment($scope.task.id, attachment.id).then(function (deleted) {
                // Send event
                attachment.cssState = "animated slideOutLeft";
                setTimeout(function () {
                    var index = $scope.task.attachments.indexOf(attachment);
                    $scope.attachments.splice(index, 1);
                }, 500);
                $scope.publish("SendTaskAttachmentDeleted", attachment);
            });
        };
        // Attachments
        $scope.attachments = [];
        var getAttachments = function () {
            boardTaskService().getAttachments($scope.task.id).then(function (attachments) {
                $scope.attachments = attachments;
            });
        }
        getAttachments();   

        // History
        var getActivityLog = function () {
            var activityRequest = {
                activity: "BoardTask",
                activityId: $scope.task.id
            };
            var $translate = $filter('translate');
            var translateOptions = {
                month: $translate('COMMON_TIMEELAPSED_MONTH'),
                days: $translate('COMMON_TIMEELAPSED_DAYS'),
                hours: $translate('COMMON_TIMEELAPSED_HOURS'),
                mins: $translate('COMMON_TIMEELAPSED_MINUTES'),
                seconds: $translate('COMMON_TIMEELAPSED_SECONDS'),
                now: $translate('COMMON_TIMEELAPSED_NOW')
            };
            activityService().getLogForActivity(activityRequest).then(function (logItems) {
                logItems.forEach(function (activity) {
                    activity.occured = timeService.getTimeElapsed(activity.activityDate, translateOptions);
                    if (activity.payLoad && typeof activity.payLoad === "string") {
                        activity.payLoad = JSON.parse(activity.payLoad); // Payload gives the whole object, in this case the Task.
                    }
                });
                $scope.activityLog = logItems;

            });
        };
        getActivityLog();

        // Get Comments
        var getComments = function () {
            boardTaskService().getCommentWithReplies($scope.task.id).then(function (taskComments) {
                $scope.total = taskComments.total;
                $scope.comments = taskComments.comments;
            });
        };
        getComments();

        $scope.deleteComment = function (comment) {
            boardTaskService().deleteComment($scope.task.id, comment.id).then(function (deleted) {
                // Send event
                comment.cssState = "animated slideOutLeft";
                setTimeout(function () {
                    var index = $scope.comments.indexOf(comment);
                    $scope.comments.splice(index, 1);
                }, 500);
                $scope.task.commentCount = deleted.taskCommentCount;
                //$scope.publish("SendTaskCommentDeleted", comment);
            });
        };


        var resetChangeFlags = function () {
            $scope.changedDescription = false;
            $scope.changedTags = false;
            $scope.changedDueDate = false;
            $scope.changedResponsible = false;
            $scope.hasAddedComments = false;
            $scope.changedStatus = false;
            changedBoard = false;
            changedResponsible = false;
            $scope.changedStartDate = false;
        };

        $scope.activeComment = "";
        $scope.activeCommentChanged = function (value) {
            $scope.hasAddedComments = true;
            $scope.activeComment = value;
        }

        var getTags = function () {

            var tagSingleString = $("#tagsSelected").val();
            var tagsArray = tagSingleString.split(",");
            var tags = [];
            for (var i = 0; i < tagsArray.length; i++) { // Hide all 
                tags.push({
                    Id: 0,
                    Tag: tagsArray[i],
                    BoardTaskId: 0
                });
            }
            return tags;
        }

        $scope.boardDateChanged = function (event) {
            const orgEvt = event.originalEvent
            const boardDate = orgEvt.detail
            $scope.task.boardDate = boardDate
            $scope.changedDueDate = true
            checkDate()
        }

        $scope.boardChanged = function (newBoard) {
            $scope.selectedBoard = newBoard;
            changedBoard = true;

        }
        function updateSubTaskDueDate(task, days) {
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'moveSubTask.html',
                controller: 'moveSubTaskController',
                resolve: {
                    parenttask: function () {
                        return task;
                    },
                    days: function () {
                        return days;
                    }
                }
            });

            modalInstance.result.then(function (result) {
            }, function () {
            });
        }

        $scope.updateBoardDate = function () {
            boardTaskService().updateDate(task.id, task.boardDate).then(function (taskMoved) {
                if (origionalBoardDate && task.subTaskCount > 0 && task.subTaskCount > task.subTaskCompletedCount) {
                    var changedDuration = timeService.getDurationInDays(origionalBoardDate, task.boardDate);
                    if (changedDuration != 0) {
                        updateSubTaskDueDate(task, changedDuration);
                    }
                }
            });
        }

        $scope.startTimeUpdated = function (event) {
            const orgEvt = event.originalEvent
            const startDate = orgEvt.detail
            $scope.task.startTime = startDate
            $scope.changedStartDate = true
            checkDate()
        }

        function updateStartDate() {
            boardTaskService().updateStartDate($scope.task.id, $scope.task.startTime).then(function () {
            });
        }

        $scope.setStatus = function (status) {
            $scope.task.status = status;
            $scope.changedStatus = true;
        };

        $scope.addComment = function (comment) {
            return $scope.saveComment(comment).then(function (savedComment) {
                $scope.comments.push(savedComment);
            });
        };

        $scope.saveComment = function (comment) {
            comment.boardTaskId = $scope.task.id;
            return boardTaskService().addComment(comment);
        };
        $scope.deleteComment = function (comment) {
            return boardTaskService().deleteComment(task.id, comment.id);
        };
        // save
        $scope.ok = function () {
            $scope.isProcessing = true;
            $scope.task.tags = getTags();
            $scope.task.color = $scope.background;
            $scope.task.attachmentCount = $scope.attachments.length;

            if (changedResponsible) {
                var responsible = {
                    taskId: $scope.task.id,
                    user: $scope.task.user
                };

                boardTaskService().assignUser(responsible)
            }

            if ($scope.changedStatus) {
                var taskStatus = { taskId: task.id, status: task.status }

                boardTaskService().updateStatus($scope.task.id, taskStatus)
            }

            boardTaskService().updateTask($scope.task).then(function (taskIds) {
                if (changedBoard) {
                    changeBoard();
                }
                if ($scope.changedDueDate) {
                    $scope.updateBoardDate();
                }

                if ($scope.changedStartDate) {
                    updateStartDate();
                }
                $uibModalInstance.close(task);
                resetChangeFlags();
            });


        };

        function changeBoard() {
            if ($scope.task.boardId !== $scope.selectedBoard.id) {
                var change = {
                    taskId: $scope.task.id,
                    fromBoardId: $scope.task.boardId,
                    toBoardId: $scope.selectedBoard.id
                };

                $scope.task.boardId = $scope.selectedBoard.id;
                if ($scope.selectedBoard.id) {
                    boardTaskService().moveTaskToActionItem($scope.task).then(function (result) {
                        $scope.publish("SendTaskChangedBoard", change);
                    });
                } else {
                    boardTaskService().moveTaskToPersonalBoard($scope.task.id).then(function (result) {
                        $scope.publish("SendTaskChangedBoard", change);
                    });
                }
            }
        }

        $scope.archive = function () {
            boardTaskService().moveTaskToArchive($scope.task.id).then(function (result) {
                $uibModalInstance.close($scope.task);
            });
        }

        $scope.unarchive = function () {
            boardTaskService().moveTaskFromArchive($scope.task.id).then(function (result) {
                $uibModalInstance.close($scope.task);
            });
        }


        $scope.delete = function () {
            var taskId = $scope.task.id;
            var repeatParentId = $scope.task.repeatParentId;
            var siblingId = $scope.task.siblingId;

            if (!repeatParentId && !siblingId) {
                boardTaskService().deleteTask(taskId).then(function () {
                });
                var container = $(".actioncontainer .dropdown");
                container.removeClass("open");
                $uibModalInstance.close($scope.task);
                return;
            }

            if (repeatParentId) {

                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'repeatdelete.html',
                    controller: 'repeatdeleteController',
                    windowClass: 'newdeviation-modal-window',
                    resolve: {
                        taskId: function () {
                            return taskId;
                        },
                        repeatParentId: function () {
                            return repeatParentId;
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $uibModalInstance.close($scope.task);
                });
            }

            if (siblingId) {
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'clonedelete.html',
                    controller: 'clonedeleteController',
                    windowClass: 'newdeviation-modal-window',
                    resolve: {
                        taskId: function () {
                            return taskId;
                        },
                        siblingId: function () {
                            return siblingId;
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $uibModalInstance.close($scope.task);
                });
            }
        }

        $scope.openParentTask = function () {
            boardTaskService().get(task.parentId).then(function (task) {
                $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'boardTask.html',
                    controller: 'boardTaskController',
                    resolve: {
                        task: function () {
                            return task;
                        },
                        activeTab: function () {
                            return "Info";
                        },
                        options: function () {
                            var options = {
                                archiveAvailable: false,
                            }
                            return options;
                        }
                    }
                });
            });
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }]);