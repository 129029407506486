angular
    .module('DigiLean')
    .directive("smarttableOwnerCell", ['smarttableService',
        function (smarttableService) {
            return {
                templateUrl: 'smarttableOwnerCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                    'users': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.displayName = "";
                    scope.user = null;

                    scope.$watch("users", function (users) {
                        if (!users) return;
                        mapUser();
                    });

                    var mapUser = function() {
                        if (scope.user === null && scope.model.row.ownerUserId && scope.users) {
                            const matchingUser = scope.users.find(u => u.id == scope.model.row.ownerUserId)
                            if (matchingUser) {
                                scope.displayName = matchingUser.ownerDisplayName;
                                scope.user = matchingUser;
                            }
                        }
                    }

                    scope.$watch("model", function (model) {
                        if (scope.model && scope.model.row) {
                            if (scope.model.row.ownerUserId) {
                                if (scope.users) {
                                    mapUser();
                                } 
                            }
                        }
                        model.getSortValue = function() {
                            if (scope.user) {
                                return scope.user.fullName;
                            }
                            return null;
                        }
                    });

                    scope.subscribe("SmartTableRowOwnerUpdated", function (cell) {
                        if (cell.id == scope.model.cell.smartTableRowId) {
                            const matchingUser = scope.users.find(u => u.id == cell.ownerUserId);
                            if (matchingUser) {
                                scope.displayName = matchingUser.ownerDisplayName;
                                scope.user = matchingUser;
                            } else {
                                scope.displayName = "";
                                scope.user = null;
                            }
                        }
                    });
                    scope.clearSelectedUser = function () {
                        var emptyUser = {
                            userId : null,
                            fullName: "",
                            displayName: ""
                        }
                        scope.user = null;
                        scope.selectUser(emptyUser);
                        
                    };
                    scope.selectUser = function (user) {
                        scope.user = user;
                        scope.displayname = user.displayName;
                        smarttableService().updateOwner(scope.model.cell.smartTableRowId, user).then(function (registeredowner) {
                        });
                    };
                }
            }
        }]);