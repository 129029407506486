import { getCompanySetting } from "@common/stores/companySettingsStore"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('dataSourceSingleSelector', ['$scope', '$rootScope', '$filter', '$uibModalInstance', '$translate', 'dataSourceService', 'hasDataSerie', 'dataSource', 'filters', 'withTarget', 'type', 'externalOnly', 'deviationService', 
    function ($scope, $rootScope, $filter, $uibModalInstance, $translate, dataSourceService, hasDataSerie, dataSource, filters, withTarget, type, externalOnly, deviationService) {        
        $scope.selectedFilters = filters;
        $scope.steps = [];
        $scope.hasDataSerie = hasDataSerie;

        $scope.data = {
            query: null
        };
        $scope.targetNeeded = false;

        $scope.datasourcefilters = null;
        $scope.dataSources = {
            list: [],
            filteredList: []
        };

        $rootScope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('DASHBOARD_DATA_SOURCE');
            $scope.steps[1].title = $translate.instant('COMMON_FILTER');
            $scope.currentStep = $scope.steps[0];
        });

        function init() {
            dataSourceService().getList().then(function (list) {
                $scope.dataSources = list;

                getCompanySetting("currency").then(function (setting) {
                    var currency = null;
                    if (setting) {
                        currency = setting
                    }
                    var internalDataSources = [];
                    if (externalOnly == false) 
                    {
                        internalDataSources = dataSourceService().getModuleList(currency);
                    }
                    else if (externalOnly === "limitedInternal") 
                    {
                        internalDataSources = dataSourceService().getLimitedModuleList(currency);
                    }

                    var allDataSources = $scope.dataSources.concat(internalDataSources);
                    $scope.dataSources = filterDataSourceByType(allDataSources);
                    $scope.resultList = $scope.dataSources.sort((a, b) => {
                        if (a.title > b.title) return 1;
                        if (a.title < b.title) return -1;
                        return 0;
                    });
                    // map selectedDataSource to updated source
                    if (dataSource) {
                        var id = dataSource.id;
                        if (!isNaN(dataSource.id) && parseInt(dataSource.id) != "NaN") {
                            id = parseInt(dataSource.id);
                        }
                        var updatedSource = $filter('filter')($scope.dataSources, { id: id }, true);
                        if (updatedSource.length > 0) {
                            if(dataSource.elements && dataSource.elements.length  > 0) {
                                updatedSource[0].elements = dataSource.elements;
                            }
                            $scope.selectedDataSource = updatedSource[0];
                        } else {
                            $scope.selectedDataSource = dataSource;
                        }
                        // If internal, we need to sync targets from settings
                        if (!$scope.selectedDataSource.targets && dataSource.targets) {
                            $scope.selectedDataSource.targets = dataSource.targets;
                            $scope.selectedDataSource.unitOfTime = dataSource.unitOfTime;
                        } else if (!$scope.selectedDataSource.targetDataSource && dataSource.targetDataSource) { // if target from another datasource, we need to sync it
                            $scope.selectedDataSource.targetDataSource = dataSource.targetDataSource;
                            $scope.selectedDataSource.targetFilters = dataSource.targetFilters;
                            $scope.selectedDataSource.targetGreenOperator = dataSource.targetGreenOperator;
                            $scope.selectedDataSource.unitOfTime = dataSource.unitOfTime;
                        }

                    }
                    $scope.dataSources.list = $scope.dataSources;
                    $scope.dataSources.filteredList = $scope.dataSources;
                });
            });

            $translate(['DASHBOARD_DATA_SOURCE', 'COMMON_FILTER']).then(function (translations) {
                $scope.steps.push({ id: 'DASHBOARD_DATA_SOURCE', title: translations.DASHBOARD_DATA_SOURCE });
                $scope.steps.push({ id: 'COMMON_FILTER', title: translations.COMMON_FILTER });
                $scope.currentStep = $scope.steps[0];
                if (withTarget) {
                    addTarget();
                    if (hasDataSerie) {
                        $scope.registrationSettings = {
                            dataSource: dataSource
                        }
                    }
                }
            });
        }
        init();

        function filterDataSourceByType(allDataSources) {
            var filtered = [];
            if (type) {
                if (type === "PROJECT") {
                    for (var i = 0; i < allDataSources.length; i++) {
                        for (var e = 0; e < allDataSources[i].elements.length; e++) {
                            var dimention = allDataSources[i].elements[e];
                            if (dimention.sourceColumn === "projectId") {
                                filtered.push(allDataSources[i]);
                                break;
                            }
                        }
                    }
                    return filtered;
                } 
                if (type === "DAY") {
                    for (var y = 0; y < allDataSources.length; y++) {
                        if(allDataSources[y].unitOfTime === 'DAY') {
                            filtered.push(allDataSources[y]);               
                        }
                    }
                    return filtered;
                }
            }
            return allDataSources;
        }

        $scope.$watch('data.query', function (q, old) {
            if (q !== null)
                $scope.searchQuery = angular.copy(q);
        });

        function addTarget() {
            $scope.steps.push({ id: 'ADMIN_DATA_DATASOURCE_TARGETS', title: $translate.instant('ADMIN_DATA_DATASOURCE_TARGETS') });
        }

        $scope.selectStep = function (step) {
            $scope.currentStep = step;
        };
        $scope.nextStep = function () {
            var currentIndex = $scope.steps.indexOf($scope.currentStep);
            if ((currentIndex + 1) < $scope.steps.length) {
                $scope.selectStep($scope.steps[currentIndex + 1]);
            }
        };
        $scope.previousStep = function () {
            var currentIndex = $scope.steps.indexOf($scope.currentStep);
            if (currentIndex > 0) {
                $scope.selectStep($scope.steps[currentIndex - 1]);
            }
        };
        $scope.selectGroupedBy = function (element) {
            $scope.selectedGroupedBy = element.sourceColumn;
        };

        $scope.selectDataSource = function (dataSource) {
            $scope.targetNeeded = false;
            $scope.hasDataSerie = true;
            $scope.selectedFilters = [];
            $scope.currentStep = $scope.steps[1];
        
            if (dataSource.id === 'DeviationConsequence' ||
                dataSource.id === 'DeviationNew' ||
                dataSource.id === 'DeviationCompleted') {
        
                if (dataSource.elements.some(element => element.customFieldId != null)) {
                    // Custom fields already exist, do nothing
                } else {
                    deviationService().getAllCustomFields().then(function (list) {
                        list.forEach(customField => {
                            dataSource.elements.push({
                                label: customField.label,
                                type: customField.type,
                                customFieldId: customField.id,
                                sourceColumn: "customField-" + customField.id,
                                dataListId: customField.dataListId
                            });
                        });
                        $scope.selectedDataSource = dataSource;
                        $scope.registrationSettings = {
                            dataSource: dataSource
                        };
                    });
                }
            } else {
                $scope.selectedDataSource = dataSource;
                $scope.registrationSettings = {
                    dataSource: dataSource
                };
            }
        };

        function arrayUniqueElements(array) {
            var a = array.concat();
            for (var i = 0; i < a.length; ++i) {
                for (var j = i + 1; j < a.length; ++j) {
                    if (a[i].label.toUpperCase() === a[j].label.toUpperCase() && a[i].type.toUpperCase() === a[j].type.toUpperCase())
                        a.splice(j--, 1);
                }
            }

            return a;
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            if (withTarget) {
                if(!$scope.selectedDataSource.unitOfTime) {
                    $scope.missingUnitOfTime = true;   
                    return;
                }
                if ($scope.selectedDataSource && !$scope.selectedDataSource.targetDataSource && ($scope.selectedDataSource.targets && $scope.selectedDataSource.targets.length == 0)) {
                    $scope.targetNeeded = true;
                    return;
                }
                
            }
            var result = {
                dataSource: $scope.selectedDataSource,
                filters: $scope.selectedFilters
            };
            $uibModalInstance.close(result);
        };

        $scope.filterChangedHandler = function (filters) {
        };

    }]);