angular.module("DigiLean").factory('suggestionService', ['$rootScope',  '$http', '$translate', 'webApiUrl',
    function ($rootScope, $http, $translate, webApiUrl) {
        function suggestionService() {
            var resource = "/suggestion";
            var resourceUrl = webApiUrl + resource;
            var improvementUrl = webApiUrl + "//improvement";
            return {
                getNextStatus: function (currentStatus) {
                    if (currentStatus === "Suggested") return "Implemented"; // New to Resolved
                    if (currentStatus === "Planned") return "Implemented"; // IN progress to Resolved
                    if (currentStatus === "InProgress") return "Implemented"; // Resolved to Rejected
                    if (currentStatus === "Implemented") return "Archived"; // Rejected to New
                    if (currentStatus === "Evaluated") return "Archived"; // Rejected to New
                    if (currentStatus === "Archived") return "Planned"; // Rejected to New
                    return "Planned";
                },
                /* If the sortable identificator is modified, remember to update the OngoingFilterSettings.cs in backend */
                getAllColumns: function (renderValue) {
                    return [
                        {
                            title: $translate.instant("COMMON_STATUS"),
                            field: 'status',
                            visible: true,
                            sortable: 'status',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_TITLE"),
                            field: 'title',
                            visible: true,
                            sortable: 'title',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("IMPROVEMENT_PRIORITY"),
                            field: 'priorityStatus',
                            visible: true,
                            sortable: 'priorityStatus',
                            getValue: renderValue,
                            dataType: "int"
                        },
                        {
                            title: $translate.instant("COMMON_CATEGORY"),
                            field: 'category',
                            visible: true,
                            sortable: 'category',
                            getValue: renderValue,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_ASSET"),
                            field: 'assetName',
                            sortable: 'assetName',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_PROJECT"),
                            field: 'projectName',
                            sortable: 'projectName',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_SUGGESTED_BY"),
                            field: 'suggestedBy',
                            sortable: 'suggestedBy',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_RESPONSIBLE"),
                            field: 'responsibleUser',
                            sortable: 'responsibleUser',
                            getValue: renderValue,
                            visible: true,
                            dataType: "text"
                        },
                        {
                            title: $translate.instant("COMMON_DUE_DATE"),
                            field: 'dueDate',
                            visible: true,
                            sortable: 'dueDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("COMMON_COST"),
                            field: 'cost',
                            visible: true,
                            sortable: 'cost',
                            getValue: renderValue,
                            dataType: "number"
                        },
                        {
                            title: $translate.instant("COMMON_BENEFIT"),
                            field: 'benefit',
                            visible: true,
                            sortable: 'benefit',
                            getValue: renderValue,
                            dataType: "number"
                        },
                        {
                            title: $translate.instant("COMMON_COST_HOURS"),
                            field: 'costHours',
                            visible: true,
                            sortable: 'costHours',
                            getValue: renderValue,
                            dataType: "number"
                        },
                        {
                            title: $translate.instant("COMMON_BENEFIT_HOURS"),
                            field: 'benefitHours',
                            visible: true,
                            sortable: 'benefitHours',
                            getValue: renderValue,
                            dataType: "number"
                        },
                        {
                            title: $translate.instant("COMMON_SUGGESTED"),
                            field: 'suggestionDate',
                            visible: true,
                            sortable: 'suggestionDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("DASHBOARD_COMPLETED"),
                            field: 'statusImplementedDate',
                            visible: true,
                            sortable: 'statusImplementedDate',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("COMMON_LAST_CHANGED"),
                            field: 'lastModified',
                            visible: true,
                            sortable: 'lastModified',
                            getValue: renderValue,
                            dataType: "date"
                        },
                        {
                            title: $translate.instant("COMMON_ID"),
                            field: 'id',
                            visible: false,
                            sortable: 'id',
                            getValue: renderValue,
                            dataType: "number"
                        },
                    ];
                },
                get: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getLikesForSuggestion: function (id) {
                    var url = resourceUrl + "/getLikesForSuggestion/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getList: function () {
                    return $http.get(resourceUrl).then(function (result) {
                        return result.data;
                    });
                },

                getTableValues: function (tableParams) {
                    var url = webApiUrl + "/improvement/getTableValues/";
                    return $http.post(url, tableParams).then(function (result) {
                        return result.data;
                    });
                },

                getOngoingFiltered: function (filterModel) {
                    var url = webApiUrl + "/improvement/getongoingfiltered";
                    return $http.post(url, filterModel).then(function (result) {
                        return result.data;
                    });
                },

                getTotalNumberOfImprovements: function (includeArchived) {
                    if (!includeArchived) includeArchived = false;
                    var url = webApiUrl + "/improvement/getTotalNumberOfImprovements?archived=" + includeArchived;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                
                getLatestSuggestions: function (page, pageSize) {
                    var url = webApiUrl + "/improvement/getLatestSuggestions?page=" + page + "&pagesize=" + pageSize;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getImplementedSuggestionByAsset: function (timeperiod) {
                    var url = webApiUrl + "/improvement/getImplementedSuggestionByAsset/";
                    return $http.post(url, timeperiod).then(function (result) {
                        return result.data;
                    });
                },

                getSuggestionStatuses: function () {
                    return [
                        {
                            status: "Suggested"
                        },
                        {
                            status: "Planned"
                        },
                        {
                            status: "Implemented"
                        },
                        {
                            status: "Archived"
                        }
                    ];
                },
                canEdit: function (id) {
                    var url = resourceUrl + "/canedit/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getPriorityStatusText: function (prioritystatusno) {
                    switch (prioritystatusno) {
                        case 1:
                            return $translate.instant('IMPROVEMENT_LOW_EFFORT_HIGH_EFFECT');
                        case 2:
                            return $translate.instant('IMPROVEMENT_HIGH_EFFORT_HIGH_EFFECT');
                        case 3:
                            return $translate.instant('IMPROVEMENT_LOW_EFFORT_LOW_EFFECT');
                        case 4:
                            return $translate.instant('IMPROVEMENT_HIGH_EFFORT_LOW_EFFECT');
                        default:
                            return $translate.instant('IMPROVEMENT_NOT_PRIORITIZED');

                    }
                },

                getMySuggestions: function () {
                    var url = resourceUrl + "/getMySuggestions/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getMyDigiLeanFeedback: function() {
                    const url = resourceUrl + "/getMyDigiLeanFeedback"
                    return $http.get(url).then(function (result) {
                        return result.data
                    })
                },
                commentOnFeedback: function(feedbackId, comment) {
                    const url = `${resourceUrl}/${feedbackId}/commentOnFeedback`
                    return $http.post(url, comment).then(function (result) {
                        return result.data
                    })
                },
                getUserListForSuggestion: function (id) {
                    var url = resourceUrl + "/getUserListForSuggestion/" + id;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getCustomerSuggestions: function () {
                    var url = resourceUrl + "/getCustomerSuggestions/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getListByStatus: function (status, timePeriod) {

                    var url = resourceUrl + "/getbystatus/" + status;
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getListByAsset: function (assetid) {

                    var url = improvementUrl + "/GetSuggestionsByAsset/" + assetid;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getListByUser: function () {
                    var url = resourceUrl + "/getbyuserid/"; // Don't need userid, because backend will know user who asks
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                addSuggestion: function (suggestion) {
                    return $http.post(resourceUrl, suggestion).then(function (result) {
                        return result.data;
                    });
                },
                updateResponsible: function (data) {
                    resourceUrl = resourceUrl + "/UpdateResponsible/";
                    return $http.put(resourceUrl, data).then(function (result) {
                        return result.data;
                    });
                },
                addFeedBackToDigiLean: function (suggestion) {
                    var url = resourceUrl + "/AddFeedBackToDigiLean";
                    return $http.post(url, suggestion).then(function (result) {
                        return result.data;
                    });
                },

                addAttachment: function (suggestionId, fileInfo) {
                    var url = resourceUrl + "/" + suggestionId + "/addAttachment";
                    return $http.post(url, fileInfo).then(function (result) {
                        return result.data; 
                    });
                },

                deleteAttachment: function (suggestionId, attachmentId) {
                    var url = resourceUrl + "/" + suggestionId + "/deleteAttachment/" + attachmentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                addComment: function (options) {
                    var url = resourceUrl + "/" + options.suggestionId + "/addcomment";
                    return $http.post(url, options).then(function (result) {
                        return result.data; // comment object is returned
                    });
                },
                getComments: function (suggestionId) {
                    var url = resourceUrl + "/getComments/" + suggestionId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                deleteComment: function (suggestionId, commentId) {
                    var url = resourceUrl + "/" + suggestionId + "/deleteComment/" + commentId;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                addLike: function (suggestionId) {
                    var url = resourceUrl + "/" + suggestionId + "/addlike";
                    return $http.post(url).then(function (result) {
                        return result.data; // like object is returned
                    });
                },

                removeLike: function (suggestionId) {
                    var url = resourceUrl + "/" + suggestionId + "/removelike";
                    return $http.delete(url).then(function (result) {
                        return result.data; // like object is returned
                    });
                },

                /* Suggestion must contain all relevant data since the entire row will be updated, except roi*/
                updateSuggestion: function (suggestion) {
                    var url = resourceUrl + "/" + suggestion.id;
                    return $http.put(url, suggestion).then(function (result) {
                        return result.data;
                    });
                },

                deleteSuggestion: function (id) {
                    var url = resourceUrl + "/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },

                updateImprovementStrategyGoal: function(suggestion){
                    var url = resourceUrl + "/" + suggestion.id + "/updateImprovementStrategyGoal";
                    return $http.put(url, suggestion).then(function (result) {
                        return result.data;
                    });
                },

                changePriorityStatus: function (options) {
                    var url = resourceUrl + "/" + options.suggestionId + "/changestatus";
                    return $http.put(url, options).then(function () {
                        return;
                    });
                },
                updateStatus: function (status) {
                    var url = resourceUrl + "/" + status.suggestionId + "/updatestatus";
                    return $http.put(url, status).then(function () {
                        return;
                    });
                },
                updateROI: function (roi) {
                    var url = resourceUrl + "/" + roi.suggestionId + "/updateROI";
                    return $http.put(url, roi).then(function () {
                        return;
                    });
                },
                updateStatusOnCustomerSuggestion: function (options) {
                    var url = resourceUrl + "/" + options.suggestionId + "/UpdateStatusOnCustomerSuggestion";
                    return $http.put(url, options).then(function () {
                        return;
                    });
                },

                activate: function (activationList) {
                    var url = resourceUrl + "/activate";
                    return $http.post(url, activationList).then(function (result) {
                        return result.data;
                    });
                },

                getImprovementsForAssetWithActionItems: function (assetId) {
                    var url = resourceUrl + "/GetImprovementsForAssetWithActionItems/" + assetId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                //TODO: implement functionality
                getNoOfSuggestionByAssetAndStatusAndMonth: function(timePeriod) {
                    var url = webApiUrl + "/improvement/GetAssetMonthCountOnStatus/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });    
                },
                
                getSuggestionsByFilter: function (options) {
                    var url = resourceUrl + "/getSuggestionsByFilter";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                }, 

                getSuggestionsForPeriod: function (options) {
                    var url = resourceUrl + "/getSuggestionsForPeriod";
                    return $http.post(url, options).then(function (result) {
                        return result.data;
                    });
                }, 


                getLeadTimeForAssets: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getLeadTimeForAssets/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getTopImprovementInitiatives: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getTopImprovementInitiatives/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getTopTimeImprovementInitiatives: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getTopTimeImprovementInitiatives/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getTimeEstimatedInitiatives: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getTimeEstimatedInitiatives/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getTimeRealization: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getTimeRealization/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getTimeInitiativeMonthlyRealized: function (timePeriod) {
                    var url = webApiUrl + "/improvement/GetTimeInitiativeMonthlyRealized/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    }); 
                },
                getEstimatedInitiatives: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getEstimatedInitiatives/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getRealization: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getRealization/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getRealizationForAssets: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getRealizationForAssets/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },
                getTimeRealizationForAssets: function(timePeriod) {
                    var url = webApiUrl + "/improvement/getTimeRealizationForAssets/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getRoiForAssets: function (timePeriod) {
                    var url = webApiUrl + "/improvement/getRoiForAssets/";
                    return $http.post(url, timePeriod).then(function (result) {
                        return result.data;
                    });
                },

                getNoImprovementPerStatus: function (assetId) {
                    if(assetId === null){
                        assetId = 0;
                    }
                    var url = webApiUrl + "/improvement/getNoImprovementPerStatus/" + assetId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                getNoProjectImprovementPerStatus: function (projectId) {
                    var url = webApiUrl + "/improvement/getNoProjectImprovementPerStatus/" + projectId;
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                

                getNoMyImprovementStatus: function () {
                    var url = webApiUrl + "/improvement/getMySuggestionsStatusNumber/";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },
                getCategories: function () {
                    var url = resourceUrl + "/getCategories";
                    return $http.get(url).then(function (result) {
                        return result.data;
                    });
                },

                createCategory: function (category) {
                    var url = webApiUrl + "/improvementAdmin/addCategory";
                    return $http.post(url, category).then(function (result) {
                        return result.data;
                    });
                },
                updateCategory: function (category) {
                    var url = webApiUrl + "/improvementAdmin/updateCategory";
                    return $http.put(url, category).then(function (result) {
                        return result.data;
                    });
                },

                deleteCategory: function (id) {
                    var url = webApiUrl + "/improvementAdmin/deleteCategory/" + id;
                    return $http.delete(url).then(function (result) {
                        return result.data;
                    });
                },
                getA3Status:function(suggestionstatus){
                    switch (suggestionstatus) {
                        case 'Suggested':
                            return "New";
                        case 'Planned':
                            return "Plan";
                        case 'InProgress':
                            return "Do";
                        case 'Implemented':
                            return "";
                        case 'Evaluated':
                            return "Act";
                        default:
                            return null;
                    }
                },
            }
        }
        return suggestionService;
    }]);
